import "./toolbar.css";
import { Control } from "ol/control.js";

export default class Toolbar extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(class_name, opt_options) {
    const options = opt_options || {};
    const holder = document.createElement("div");
    super({
      element: holder,
      target: options.target,
    });
    const that = this;
    holder.className = class_name;
    if (Array.isArray(options.toolbar)) {
      options.toolbar.forEach(buttons => {
        const toolbar = document.createElement("div");
        toolbar.className = "ol-unselectable ol-control";

        buttons.forEach(btn => {
          let button;
          if (typeof btn.element !== "undefined") {
            button = document.createElement(btn.element);
            delete btn.element;
          } else {
            button = document.createElement("button");
            button.type = "button";
          }
          let empty = true;
          for (var prop in btn) {
            if (prop === "text") button.innerHTML = btn[prop];
            else if (prop === "onclick" && typeof btn[prop] === "function") {
              button.addEventListener("click", btn[prop].bind(button));
            } else {
              button.setAttribute(prop, btn[prop]);
            }
            empty = false;
          }
          if (empty) button.className = "div"; // corrected the class assignment
          button.addEventListener("click", e => {
            if (button.hasAttribute("data-toggle")) button.classList.toggle("selected");
            let data_attr = "";
            if (button.hasAttribute("data-radio")) {
              data_attr = button.getAttribute("data-radio");
              
              holder.querySelectorAll(`[data-radio="${data_attr}"]`).forEach(b => {
                if (b !== button) b.classList.remove("selected");
              });
              if (button.classList.contains('toggle'))
                button.classList.toggle("selected");
              else
                button.classList.add("selected");
            }

            if (button.hasAttribute("data-switch")) {
              data_attr = button.getAttribute("data-switch");
              holder.querySelectorAll(`[data-switch="${data_attr}"]`).forEach(b => {
                b.classList.toggle("selected");
              });
            }
            if (typeof that.onclick === 'function') {
              that.onclick.bind(that)(button);
            }
            var event = new CustomEvent("toolbar", { "detail": button });
            document.dispatchEvent(event);
          });
          toolbar.appendChild(button);
        });
        holder.appendChild(toolbar);
      });
    }
    this._holder = holder;
  }
  show() {
    this._holder.style.display = "";
  }
  hide() {
    this._holder.style.display = "none";
  }
  getButtons(selector) {
    return this._holder.querySelectorAll(selector);
  }
}
