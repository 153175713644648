import {XYZ} from 'ol/source.js';
import {WebGLTile as TileLayer} from 'ol/layer.js';

// The method used to extract elevations from the DEM.
// In this case the format used is Terrarium
// red * 256 + green + blue / 256 - 32768
//
// Other frequently used methods include the Mapbox format
// (red * 256 * 256 + green * 256 + blue) * 0.1 - 10000
//
 function elevation(xOffset, yOffset) {
  const red = ['band', 1, xOffset, yOffset];
  const green = ['band', 2, xOffset, yOffset];
  const blue = ['band', 3, xOffset, yOffset];

  // band math operates on normalized values from 0-1
  // so we scale by 255
  return [
    '+',
    ['*', 256* 256, red],
    ['*', 256, green],
    ['*', 256 / 256, blue],
    -32767,
  ];
 }
// Generates a shaded relief image given elevation data.  Uses a 3x3
// neighborhood for determining slope and aspect.

export class Terrain {
    constructor() {
		const dp = ['*', 2, ['resolution']];
		const z0x = ['*', ['var', 'vert'], elevation(-1, 0)];
		const z1x = ['*', ['var', 'vert'], elevation(1, 0)];
		const dzdx = ['/', ['-', z1x, z0x], dp];
		const z0y = ['*', ['var', 'vert'], elevation(0, -1)];
		const z1y = ['*', ['var', 'vert'], elevation(0, 1)];
		const dzdy = ['/', ['-', z1y, z0y], dp];
		const slope = ['atan', ['sqrt', ['+', ['^', dzdx, 2], ['^', dzdy, 2]]]];
		const aspect = ['clamp', ['atan', ['-', 0, dzdx], dzdy], -Math.PI, Math.PI];
		const sunEl = ['*', 0.017453292519943295 /* Math.PI / 180 */, ['var', 'sunEl']];
		const sunAz =  ['*', 0.017453292519943295 /* Math.PI / 180 */, ['var', 'sunAz']];

		const cosIncidence = [
		  '+',
		  ['*', ['sin', sunEl], ['cos', slope]],
		  ['*', ['cos', sunEl], ['sin', slope], ['cos', ['-', sunAz, aspect]]],
		];
		const scaled = ['*', 255, cosIncidence];
		const scaled_r = ['*', 239, cosIncidence];
		const scaled_g = ['*', 189, cosIncidence];
		const scaled_b = ['*', 139, cosIncidence];
		
		this.variables={'vert':36, 'sunEl':45, 'sunAz':45};
		this.source=new XYZ({
			url: 'https://tiles.behrah.com/dem/{z}/{x}/{y}.webp',
			imageSize:512,
			maxZoom: 14
		});
		this.layer = new TileLayer({
		  opacity: 1,
		  source: this.source,
		  style: {
			variables: this.variables,
			color: ['color', scaled_r,scaled_g,scaled_b,0.85],
		  },
		  zIndex:10
		});
		return this.layer;
	}
}