import './styler.css';

import Dialog from './dialog';
import {
    createStyles,
    extractStyles,
	customStyles
}
from './styles.js';

import {
    LineString,
    Circle,
    Polygon
}
from 'ol/geom';

const template = `
	<div class="%type%">
		<div class="mb-3">
			<label class="fs-bold mb-1">متن</label>
			<input type="trxt" class="form-control" name="text" value="Test Text">
		</div>
		<div class="for-point row mb-3">
			<div class="col-md-8">
				<label>نوع مکان نما</label>
				<div class="d-flex justify-content-between">
					<label><input type="radio" name="icon" value="marker" class="mx-2"><i class="icon-location"></i></label>
					<label><input type="radio" name="icon" value="circle" class="mx-2" checked><i class="icon-circle"></i></label>
					<label><input type="radio" name="icon" value="flag1" class="mx-2"><i class="icon-flag"></i></label>
					<label><input type="radio" name="icon" value="flag2" class="mx-2"><i class="icon-flag-check"></i></label>
					<label><input type="radio" name="icon" value="none" class="mx-2">هیچکدام</label>
				</div>
			</div>
			<div class="col-md-4">
				<label>اندازه</label>
				<input type="range" name="markerscale" value="1" class="form-range" min="1" max="10" step="0.1">
			</div>
		</div>
		<div class="coloring row">
			<div class="col-6 mb-3">
				<div class="mb-3">
					<label class="text-bold mb-1">رنگ خط</label>
					<input type="color" class="form-control" name="strokecolor" value="#ffffff">
				</div>
				<div class="mb3">
					<label class="fs-bold mb-1">قطر خط</label>
					<input type="range" class="w-100" name="strokwidth" min="0" max="16" value="2">
				</div>
				<div class="mb3">
					<label class="fs-bold mb-1">نوع خط</label>
					<select class="form-select" name="lineDash">
						<option value='0'>ساده</option>
						<option value='3'>نقطه چین</option>
						<option value='10'>خط چین</option>
					</select>
				</div>
			</div>
			<div class="col-6 mb-3">
				<div class="mb-3">
					<label class="fs-bold mb-1">رنگ زمینه</label>
					<input type="color" class="form-control" name="fillcolor" value='#808080'>
				</div>
				<div class="mb-4">
					<label class="fs-bold mb-1">شفافیت زمینه</label>
					<input type="range" class="w-100" name="fillopacity" min="0" max="255" value="0">
				</div>
				<div class="form-check">
					<label class="form-check-label">
					<input class="form-check-input float-none ms-2" type="checkbox" value="yes" name="showInfo" checked>
					نمایش مختصات و ابعاد
				</label>
			</div>
		</div>
		</div>
		
	</div>
`;

class Styler {
    constructor(invalidLayers = []) {
        this.map = null;
        this.active = false;
        this.invalidLayers = invalidLayers;
    }

    style(feature) {
        const styler = this;
        const geom = feature.getGeometry().getType();
        let values = feature.get('_values')
            let dlg = template.replaceAll('%type%', geom);
        const dialog = new Dialog('default-dialog', 'تنظیمات شکل', dlg, [{
                        'text': 'تایید',
                        'class': 'btn btn-primary',
                        'id': 'btn-ok',
                        'data-bs-dismiss': 'modal',
                    }, {
                        'text': 'بستن',
                        'class': 'btn btn-outline-dark',
                        'id': 'btn-cancel',
                        'data-bs-dismiss': 'modal',
                    }, {
                        'text': 'حذف',
                        'class': 'btn btn-outline-danger',
                        'id': 'btn-remove',
                        'data-bs-dismiss': 'modal',
                    },
                ], values);
        dialog.onclick = function (btn) {
            if (btn.id == 'btn-remove') {
                map.getLayers().forEach(function (layer) {
                    var source = layer.getSource();
                    // Check if the source has the removeFeature function
                    if (typeof source.removeFeature === 'function') {
                        // Check if the feature is part of the source
                        if (source.getFeatures().includes(feature)) {
                            // Remove the feature from the source
                            source.removeFeature(feature);
                        }
                    }
                });
                return;
            }
            if (btn.id != 'btn-ok')
                return;
            values = this.getValues();
            const strokewidth = parseInt(values.strokwidth);
            let styles;
            if (geom == 'Point') {
                const fontsize = 16,
                iconsize = 24,
                scale = parseFloat(values.markerscale);
                let offsetY = 20;
                if (['marker', 'flag1', 'flag2'].indexOf(values.icon) >= 0) {
                    styles = [{
                            text: {
                                maxAngle: 0,
                                text: values.text,
                                font: fontsize + 'px titr',
                                offsetY: offsetY,
                                fill: {
                                    color: '#000000'
                                },
                                stroke: {
                                    color: '#ffffff',
                                    width: 2,
                                },
                            }
                        }, {
                            text: {
                                maxAngle: 0,
                                text: values.icon == 'flag1' ? "\uF024" : values.icon == 'flag2' ? "\uF11E" : "\uE8B4",
                                font: iconsize + 'px icons',
                                offsetX: values.icon == 'marker' ? 0 : (strokewidth / 2) + (iconsize / 2 - 3) * scale,
                                offsetY:  - (scale * iconsize / 2) - (strokewidth / 2),
                                fill: {
                                    color: values.fillcolor + (255 - values.fillopacity).toString(16).padStart(2, '0')
                                },
                                stroke: {
                                    color: values.strokecolor,
                                    width: strokewidth / scale,
                                    lineDash: values.lineDash > 0 ? [values.lineDash/values.markerscale, values.lineDash/values.markerscale] : null
                                },
                                scale: values.markerscale
                            }
                        },
                    ];
                }
                if (values.icon == 'circle') {
                    styles = [{
                            image: {
                                circle: {
                                    radius: (scale * 10) - 3,
                                    fill: {
                                        color: values.fillcolor + (255 - values.fillopacity).toString(16).padStart(2, '0')
                                    },
                                    stroke: {
                                        color: values.strokecolor,
                                        width: strokewidth,
                                        lineDash: values.lineDash > 0 ? [values.lineDash, values.lineDash] : null
                                    },
                                }
                            },
                        }, {
                            text: {
                                maxAngle: 0,
                                text: values.text,
                                font: fontsize + 'px titr',
                                offsetY: fontsize + (scale * 10) + (strokewidth / 2),
                                fill: {
                                    color: '#000000'
                                },
                                stroke: {
                                    color: '#ffffff',
                                    width: 2
                                },
                            },

                        }
                    ];
                }
                if (values.icon == 'none') {
                    styles = [{
                            text: {
                                maxAngle: 0,
                                text: values.text,
                                font: (fontsize * scale) + 'px titr',
                                fill: {
                                    color: values.fillcolor + (255 - values.fillopacity).toString(16).padStart(2, '0')
                                },
                                stroke: {
                                    color: values.strokecolor,
                                    width: strokewidth
                                },
                            },
                        }
                    ];
                }
            } else {
                styles =
                    [{
                        text: {
                            text: values.text,
                            font: 'bold 18px titr',
                            offsetY: (values.marker == 'none' || (geom != 'Point' && !values.measure)) ? 0 : 20,
                            fill: {
                                color: '#000000'
                            },
                            stroke: {
                                color: '#ffffff',
                                width: 3
                            },
                            placement: geom == 'LineString' ? 'line' : 'point',
                        },
                        stroke: {
                            color: values.strokecolor,
                            width: values.strokwidth,
                            lineDash: values.lineDash > 0 ? [values.lineDash, values.lineDash] : null
                        },
                        fill: {
                            color: values.fillcolor + (255 - values.fillopacity).toString(16).padStart(2, '0')
                        }
                    }
                ]
            }
            feature.set('_values', values);
            feature.set('_styles', styles);			
            feature.setStyle((feature)=>customStyles(feature))
        }
        dialog.onclose = e => {
            if (typeof(styler.onclose) == 'function')
                styler.onclose(feature)
        }
    }

    handlePointerClick(e) {
        if (!this.active)
            return;

        const feature = this.map.forEachFeatureAtPixel(
                e.pixel,
                (feat) => feat, {
                layerFilter: (layer) => this.layerFilter(layer)
            });
        if (feature) {
            this.style(feature);
        }
    }

    handlePointerMove(e) {
        if (!this.active)
            return;

        const pixel = this.map.getEventPixel(e.originalEvent);
        const hit = this.map.hasFeatureAtPixel(pixel, {
            layerFilter: (layer) => this.layerFilter(layer)
        });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.map.getTargetElement().classList.toggle('pointer', hit);
        }, 5);
    }
    layerFilter(layer) {
        return this.invalidLayers.indexOf(layer) === -1;
    }
    setMap(map) {
        if (this.map) {
            // Remove previous event listeners if any
            this.map.un('pointermove', this.boundHandlePointerMove);
            this.map.un('click', this.boundHandlePointerClick);
            this.active = false;
        }

        this.map = map;

        if (this.map) {
            this.boundHandlePointerMove = this.handlePointerMove.bind(this);
            this.boundHandlePointerClick = this.handlePointerClick.bind(this);

            this.map.on('pointermove', this.boundHandlePointerMove);
            this.map.on('click', this.boundHandlePointerClick);
            this.active = true;
        }
    }

    setActive(active) {
        if (this.active === active)
            return;

        this.active = active;
    }
    setInvalidLayers(invalidLayers) {
        this.invalidLayers = invalidLayers;
    }
}

const featureStyler = new Styler();
export default featureStyler;

document.addEventListener('toolbar', e => {
    const btn = e.detail,
    selected = btn.classList.contains('selected');
    if (btn.id === 'styler')
        featureStyler.setActive(selected);
    else
        featureStyler.setActive(false);

});
