import map from './map.js';
import MVT from 'ol/format/MVT.js';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import VectorTile from 'ol/source/VectorTile.js';
import {
    OLFeature
}
from './utils.js';
import Feature from 'ol/Feature.js';
import {
    Fill,
    Icon,
    Stroke,
    Style,
    Text
}
from 'ol/style.js';
import {
    Point,
    LineString,
    Polygon,
    MultiPoint,
    MultiLineString,
    MultiPolygon
}
from 'ol/geom';

const source = new VectorTile({
    format: new MVT(),
    url: 'https://tiles.behrah.com/cur/{z}/{x}/{y}.pbf',
    minZoom: 9,
    maxZoom: 14,
});


export class Contour extends VectorTileLayer {
    constructor() {
        super({
            source: source,
            declutter: true,
            zIndex: 110,
            minZoom: 11,
            style: (feature, resolution) => {
                const p = feature.getProperties();
                if (p.layer != 'contour')
                    return;
                if (this.only100 && p.height % 100 != 0)
                    return;
                const width = p.height % 100 == 0 ? 2 : 1;
				if (this._features.indexOf(feature)<0)
                this._features.push(feature);
			console.log('contour styling called')
                return [
                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(255,255,255,0.9)',
                            width: width + 2,
                        }),
                    }),
                    new Style({
                        text: new Text({
                            text: p.height + '',
                            placement: 'line',
                            font: 'bold 12px titr',
                            overflow: true,
                            fill: new Fill({
                                color: 'SaddleBrown',
                            }),
                            stroke: new Stroke({
                                width: 3,
                                color: 'white'
                            }),
                        }),
                        stroke: new Stroke({
                            color: this.only100 ? 'rgba(47, 79, 79,0.5)' : p.height % 100 == 0 ? 'rgba(0,16,16,0.6)' : 'rgba(47, 79, 79,0.5)',
                            width: width,
                        }),
                    }),
                ];
            }
        });
        this._features = [];
        source.on('tileloadstart', e => {
            this._features = [];
        })
        try {
            const settings = JSON.parse(localStorage.getItem('settings'));
            this.only100 = settings.topography.length > 1;
        } catch (e) {}
        document.addEventListener('settingsChanged', e => {
            this.only100 = e.detail.topography.length > 1
        })
    }
	resetFeatures(){
		this._features=[];
	}
    getFeatures() {
        return this._features.map(f => {
            const feature = OLFeature(f);
           const properties = f.getProperties();
            feature.set('name', properties.height+'m');
            return feature
        });
    }
}
