import "./dialog.css";
import Modal from "bootstrap/js/src/modal";
function randomstring(L) {
    var s = "";
    var randomchar = function () {
        var n = Math.floor(Math.random() * 62);
        if (n < 10)
            return n; //1-10
        if (n < 36)
            return String.fromCharCode(n + 55); //A-Z
        return String.fromCharCode(n + 61); //a-z
    }
    while (s.length < L)
        s += randomchar();
    return s;
}
export default class Dialog {
    constructor(class_name, title, message, buttons, values = null) {
        let dialog = this;
        this.id = "dlg-" + randomstring(16);
        const div = document.createElement("div");
        div.id = this.id;
        div.className = "modal fade ";
        div.setAttribute("role", "dialog");
        div.setAttribute("title", title);
        div.setAttribute("aria-label", "Info Dialog");
        if (!buttons) {
            var buttons = [{
                    "text": "بستن",
                    "data-bs-dismiss": "modal",
                    "class": "btn btn-primary"
                }
            ];
        }

        let footer = [];
        if (buttons.length > 0) {
            buttons.forEach(btn => {
                let button;
                if (btn.element) {
                    button = document.createElement(btn.element);
                    delete btn.element;
                } else {
                    button = document.createElement("button");
                    button.type = "button";
                }
                for (var prop in btn)
                    if (prop == "text")
                        button.innerHTML = btn[prop];
                    else
                        button.setAttribute(prop, btn[prop]);
                footer.push(button);
            });
        }
        div.innerHTML = `<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
		  <div class="modal-header">
			<h5 class="modal-title">${title}</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		  </div>
		  <div class="modal-body">${message}</div>
		  </div></div>`;
        if (footer.length > 0) {
            let content = div.querySelector(".modal-content");
            let modal_footer = document.createElement("div");
            modal_footer.className = "modal-footer";
            content.appendChild(modal_footer);
            footer.forEach(btn => {
                modal_footer.appendChild(btn)
            });
        }
        // if (document.getElementById("map"))
        // document.getElementById("map").appendChild(div);
        // else
        document.body.appendChild(div);

        let modal = new Modal(div, {
            backdrop: "static",
            focus: true,
            keyboard: true
        });
        modal.visibile = false;
        div.querySelectorAll("button").forEach(button => {
            button.addEventListener("click", (e) => {
                if (typeof(dialog.onclick) == "function")
                    dialog.onclick.bind(dialog)(button);
                var event = new CustomEvent("modalButton", {
                    "detail": button
                });
                document.dispatchEvent(event);
            });
        });
        div.addEventListener("hidden.bs.modal", e => {
            if (typeof(dialog.onclose) == "function")
                dialog.onclose.bind(dialog)(div);
            modal.dispose()
            div.parentElement.removeChild(div);
        });
        div.addEventListener("show.bs.modal", event => {
            if (typeof(values) != "undefined" && values != null)
                dialog.setValues(values);
            if (!Array.isArray(class_name)) {
                class_name = class_name.split(' ');
            }
            if (Array.isArray(class_name)) {
                // Filter out any empty strings
                class_name = class_name.filter(cls => cls.trim() !== '');

                if (class_name.length > 0) {
                    div.querySelector('.modal-dialog').classList.add(...class_name);
                }
            }
        });
        div.addEventListener("shown.bs.modal", event => {
            modal.visibile = true;
            if (typeof(dialog.onshow) == "function")
                dialog.onshow.bind(dialog)(div);
        });
        this._modal = modal;
        this._element = div;
        this._body = div.querySelector(".modal-body");
        if (message)
            this.show();
        return this;
    }
    element() {
        return this._element;
    }
    body(html) {
        if (typeof(html) !== "undefined")
            this._body.innerHTML = html;
        return this._body;
    }
    show(html) {
        if (typeof(html) !== "undefined")
            this._body.innerHTML = html;
        this._modal.show();
    }
    hide() {
        let dialog = this;
        if (this._modal.visibile)
            this._modal.hide();
        else
            setTimeout(() => {
                dialog.hide()
            }, 1)
    }
    getValues() {
        const values = {};
        this._element.querySelectorAll("input, select, textarea").forEach(input => {
            if (input.type === "checkbox") {
                if (typeof values[input.name] === "undefined")
                    values[input.name] = [];
                if (input.checked)
                    values[input.name].push(input.value);
            } else if (input.type === "radio") {
                if (input.checked)
                    values[input.name] = input.value;
            } else if (input.tagName === "SELECT") {
                // Handle select elements
                values[input.name] = input.value;
            } else {
                // For input, textarea, and other elements
                values[input.name] = input.value;
            }
        });

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                // If the property is a string and it represents a number, convert it to a number
                if (typeof values[key] === 'string' && !isNaN(values[key])) {
                    values[key] = Number(values[key]);
                }
            }
        }

        return values;
    }

    setValues(values) {
        this._element.querySelectorAll("input, select, textarea").forEach(input => {
            if (input.type === "checkbox") {
                if (values[input.name])
                    input.checked = values[input.name].indexOf(input.value) > -1;
            } else if (input.type === "radio") {
                input.checked = (typeof values[input.name] !== "undefined") && (values[input.name] == input.value);
            } else if (input.tagName === "SELECT") {
                // Handle select elements
                input.value = values[input.name];
            } else {
                // For input, textarea, and other elements
                input.value = (typeof values[input.name] !== "undefined") ? values[input.name] : '';
            }
        });
    }
}
