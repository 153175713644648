import './export.css';
import map, {
    getMapFeatures
}
from './map.js';
import Dialog from './dialog.js';
import html2canvas from 'html2canvas';
import ScaleLine from 'ol/control/ScaleLine.js';
import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML.js';
import JSZip from 'jszip';
import shpwrite from '@mapbox/shp-write';

import {
    METERS_PER_UNIT,
    getPointResolution
}
from 'ol/proj.js';
import {
    toSize
}
from 'ol/size';
import {
    getSettings,
    setSettings
}
from './settings.js';
import {
    replaceExtension,
    newFilename,
    saveToFile
}
from './utils';

// Ensure all paths and filenames are correct
// Further code logic goes here...

const extesions = ['jpg', 'png', 'geojson', 'kmz', 'zip'];
const html = `
	<div class="row h-100">
		<div class="col-md-9 position-relative order-md-2">
			<div id="tmpContainer">
				<div id="tmpMap"></div>
			</div>
		</div>
		<div class="col-md-3 px-3 order-md-1">
			<div class="row">
				<div class="mb-3">
					<label class="form-label">نوع خروجی</label>
					<select class="form-select" name="output">
						<option value="0">تصویر JPG</option>
						<option value="1">تصویر PNG</option>
						<option value="2">فایل برداری (Vector) GEOJSON</option>
						<option value="3">فایل برداری (Vector) KMZ</option>
						<option value="4">فایل برداری (Vector) SHP</option>
					</select>
				</div>
				<div class="col-12">
				<div class="row">
					<div class="col-md-7 mb-3">
						<label class="form-label">اندازه تصویر</label>
						<select class="form-select" name="size">
							<option value="0">۱۹۲۰ × ۱۰۸۰ پیکسل</option>
							<option value="1">۳۸۴۰ × ۲۱۶۰ پیکسل</option>
							<option value="2">۲۹۷ × ۲۱۰ میلیمتر - A4</option>
							<option value="3">۴۲۰ × ۲۹۷ میلیمتر - A3</option>
							<option value="4">۵۹۵ × ۴۲۰ میلیمتر - A2</option>
							<option value="5">۸۴۱ × ۵۹۵ میلیمتر - A1</option>
						</select>
					</div>
					<div class="col-md-5 mb-3">
						<label class="form-label">کیفیت</label>
						<select class="form-select for-print" name="dpi" disabled>
							<option value="0">۷۲ Dpi</option>
							<option value="1">۱۵۰ Dpi</option>
							<option value="2">۳۰۰ Dpi</option>
						</select>
					</div>
				</div>
				</div>
				<div class="col-md-6 mb-3">
					<label class="form-label">جهت تصویر</label>
					<select class="form-select" name="direction">
						<option value="0">افقی</option>
						<option value="1">عمودی</option>
					</select>
				</div>
				<div class="col-md-6 mb-3">
					<label class="form-label">نمایش شمال</label>
					<select class="form-select" name="north">
						<option value="0">خیر</option>
						<option value="1" selected>بله</option>
					</select>
				</div>
				<div class="col-md-6 mb-3">
					<label class="form-label">مقیاس نقش</label>
					<select class="form-select" name="scale">
						<option value="0">متغیر</option>
						<option value="2">ثابت ۱:۲,۰۰۰</option>
						<option value="5">ثابت ۱:۵,۰۰۰</option>
						<option value="10">ثابت ۱:۱۰,۰۰۰</option>
						<option value="50">ثابت ۱:۵۰,۰۰۰</option>
						<option value="100">ثابت ۱:۱۰۰,۰۰۰</option>
						<option value="250">ثابت ۱:۲۵۰,۰۰۰</option>
					</select>
				</div>
				<div class="col-md-6 mb-3">
					<label class="form-label">نمایش مقیاس</label>
					<select class="form-select" name="scaleshow">
						<option value="1" selected>بله</option>
						<option value="0">خیر</option>
					</select>
				</div>
				<div class="col">
					<label class="form-label">نام فایل</label>
					<input class="form-control ltr" name="filename">
				</div>
			</div>
		</div>
	</div>
`;

function createImage(div, values) {

    // Store and reset the transform for rendering
    const originalTransform = div.style.transform;
    div.style.transform = '';

    let width = values.size[0],
    height = values.size[1];

    html2canvas(div, {
        useCORS: true
    }).then((canvas) => {
        // Restore the original transform
        div.style.transform = originalTransform;
        // Create a new canvas element with the specified size
        const newCanvas = document.createElement('canvas');
        newCanvas.width = width;
        newCanvas.height = height;
        const context = newCanvas.getContext('2d');

        // Enable image smoothing for better quality
        context.imageSmoothingEnabled = true;
        context.imageSmoothingQuality = 'high';

        // Draw the original canvas onto the new canvas, scaling to fit the specified size
        context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, width, height);
        // Create a data URL and download the image
        newCanvas.toBlob(blob => {
            saveToFile(blob, values.filename);
        }, values.output, 0.9);
    }).catch((e) => {
        div.style.transform = originalTransform; // Restore the original transform in case of error
    });
}

function adjustMapSize(size) {
    const tmpContainer = document.getElementById('tmpContainer');
    const tmpMap = document.getElementById('tmpMap');
    const width = size[0],
    height = size[1];
    // Change the aspect-ratio of #tmpContainer
    tmpContainer.style.aspectRatio = `${width} / ${height}`;

    // Change the width and height of #tmpMap
    tmpMap.style.width = `${width}px`;
    tmpMap.style.height = `${height}px`;

    function updateMapPosition() {
        // Calculate the scale to fit #tmpMap within #tmpContainer
        const containerWidth = tmpContainer.clientWidth;
        const containerHeight = tmpContainer.clientHeight;

        const scaleWidth = containerWidth / width;
        const scaleHeight = containerHeight / height;
        const scale = Math.min(scaleWidth, scaleHeight);

        // Set the transform scale of #tmpMap
        tmpMap.style.transform = `scale(${scale})`;

        // Calculate the new width and height of the scaled #tmpMap
        const scaledWidth = width * scale;
        const scaledHeight = height * scale;

        // Calculate margins to center #tmpMap within #tmpContainer
        const marginTop =  - (height - scaledHeight) / 2;
        const marginRight =  - (width - scaledWidth) / 2;

        // Set margins to center #tmpMap
        tmpMap.style.top = `${marginTop}px`;
        tmpMap.style.right = `${marginRight}px`;
        tmpMap.setAttribute('scaledWidth', scaledWidth);
        tmpMap.setAttribute('scaledHeight', scaledHeight);
        //map.setSize(toSize([width, height]))
        map.updateSize();
    }

    // Initial adjustment
    updateMapPosition();

    // Update position on window resize
    const resizeListener = updateMapPosition;
    window.addEventListener('resize', resizeListener);

/*     // Mutation observer to detect removal of #tmpContainer
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.removedNodes.forEach((node) => {
                if (node === tmpContainer) {
                    // Remove event listener if #tmpContainer is removed
                    window.removeEventListener('resize', resizeListener);
                    observer.disconnect();
                }
            });
        });
    });

    // Observe the parent of #tmpContainer for childList changes
    observer.observe(tmpContainer.parentNode, {
        childList: true
    });

 */
}
const mapSize = [
    [1920, 1080],
    [3840, 2160]
];
export default class mapExport {
    constructor() {
        const mapSettings = getSettings();
        const data = {
            filename: newFilename('jpg'),
            output: 0,
            size: 0,
            dpi: 0,
            scale: 0,
            scaleshow: 0,
            north: 0,
            direction: 0
        }
        const dialog = new Dialog('modal-fullscreen info-dark', 'تصویربرداری از نقشه', html, [{
                        "text": "test",
                        'id': 'ok',
                        'class': 'btn btn-primary'
                    }
                ], data);
        dialog.onshow = (div) => {
            //adjustMapSize(mapSize[0]);
            this.mapTarget = map.getTargetElement();
            this.mapControls = map.getControls().getArray();

            // Filter and remove controls that are NOT instances of ScaleLine
            this.mapControls.forEach(c => {
                if (!(c instanceof ScaleLine)) {
                    c.setMap(null); // Remove the control from the map
                } else
                    this.scaleLine = c;
            });
            map.setTarget('tmpMap');
            div.addEventListener('change', e => {
                const element = e.target;
                if (element.name == 'size')
                    div.querySelector('.for-print').disabled = element.value < 2;
                if (element.name == 'output') {
                    if (element.value > 1) {
                        const settings = structuredClone(mapSettings);
                        settings.background = 'none';
                        setSettings(settings);
                    } else {
                        setSettings(mapSettings);
                    }
                }
                const values = dialog.getValues();
                if (!values.filename || values.filename.trim() == '')
                    values.filename = newFilename(extesions[values.output]);
                else
                    values.filename = replaceExtension(values.filename, extesions[values.output]);
                dialog.setValues(values)
                this.updateMap(values)
            })
            const values = dialog.getValues();
            this.updateMap(values)
        };
        dialog.onclose = () => {
            setSettings(mapSettings);
            map.setTarget(this.mapTarget);
            this.mapControls.forEach(c => c.setMap(map));
        }
        dialog.onclick = btn => {
            if (btn.id == 'ok') {
                const values = dialog.getValues();
                if (values.output < 2) {
                    this.convertValues(values)
                    createImage(map.getTargetElement(), values);
                } else {
                    getMapFeatures(features => {
                        let featuresString;
                        switch (values.output) {
                        case 2:
                            featuresString = new GeoJSON().writeFeatures(features, {
                                featureProjection: 'EPSG:3857',
                                rightHanded: true,
                                decimals: 6
                            });
                            saveToFile(featuresString, values.filename, 'application/geo+json');
                            break;
                        case 3:
                            const kmlMime = 'application/vnd.google-earth.kml+xml';
                            featuresString = new KML({
                                extractStyles: true,
                                showPointNames: true, // Ensure point names are shown
                                writeStyles: true
                            }).writeFeatures(features, {
                                featureProjection: 'EPSG:3857',
                                rightHanded: true,
                                decimals: 6
                            });
                            const kmlBlob = new Blob([featuresString], {
                                type: kmlMime
                            });

                            // Create a new JSZip instance
                            const zip = new JSZip();

                            // Add the KML file to the zip archive
                            zip.file(replaceExtension(values.filename, 'kml'), kmlBlob);
                            zip.generateAsync({
                                type: "blob"
                            })
                            .then(function (content) {
                                // Create a download link for the KMZ file
                                const kmzBlob = new Blob([content], {
                                    type: 'application/vnd.google-earth.kmz'
                                });
                                saveToFile(kmzBlob, values.filename)
                            });
                            saveToFile(featuresString, values.filename, kmlMime);
                            break;

                        case 4:
                            featuresString = new GeoJSON().writeFeaturesObject(features, {
                                featureProjection: 'EPSG:3857',
                                rightHanded: true,
                                decimals: 7
                            });
                            const options = {
                                folder: 'topo-layers',
                                filename: "values.filename",
                                outputType: "blob",
                                compression: "DEFLATE",
                                types: {
                                    point: 'points',
                                    line: 'lines',
                                    polygon: 'polygons'
                                }
                            };
                            shpwrite.zip(featuresString, options)
                            .then(blob => {
                                console.log(blob)
                                saveToFile(blob, 'text.zip')

                            })

                            break;
                        }

                    });
                }
            }
        }

    }

    convertValues(values) {
        values.dpi = values.dpi == 1 ? 150 : values.dpi == 2 ? 300 : 72;
        values.dpm = values.dpi / 25.4;
        let size;
        if (values.size < 2) {
            size = [mapSize[values.size][0], mapSize[values.size][1]];
        } else {
            switch (values.size) {
            case 2:
                size = [297, 210];
                break;
            case 3:
                size = [420, 297];
                break;
            case 4:
                size = [595, 420];
                break;
            case 5:
                size = [841, 595];
                break;
            }
            size[0] = Math.round(size[0] * values.dpm);
            size[1] = Math.round(size[1] * values.dpm);
        }
        values.output = (values.output == 0) ? 'image/jpeg' : (values.output == 1) ? 'image/png' : (values.output == 2) ? 'application/geo+json' : (values.output == 3) ? 'application/vnd.google-earth.kmz' : 'application/octet-stream';
        if (values.direction == 1)
            size = size.reverse();
        values.size = size;
        return values;
    }
    updateMap(values) {
        this.convertValues(values);
        if (values.scale > 0) {
            const view = map.getView();
            let projection = view.getProjection();
            const scaleResolution = values.scale / getPointResolution(projection, values.dpm, view.getCenter());
            this.scaleLine.setDpi(values.dpi);
            adjustMapSize(values.size);
            view.setResolution(scaleResolution);
        } else {
            adjustMapSize(values.size);
        }
    }
}
