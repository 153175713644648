import map from './map.js';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {applyStyle} from 'ol-mapbox-style';
import {OLFeature} from './utils.js';

const all_layers = [
    "boundary",
    "building",
    "place",
    "poi",
    "transportation",
    "transportation_name",
    "aeroway",
    "aerodrome_label",
    //    "housenumber",
    "water",
    "water_name",
    "waterway",
    "landuse",
    "landcover",
    "mountain_peak",
    "park",
    "globallandcover",
];

const layers_group = {
    "admins": ["boundary", "globallandcover"],
    "buildings": ["building"],
	"poi":["poi"],
	"terminals":["aeroway","bus"],
    "labels": ["place", "transportation_name", "aerodrome_label", "water_name"],
    "roads": ["transportation"],
    "waters": ["water", "waterway"],
    "lands": ["landuse", "landcover", "park",],
	"mountains":["mountain_peak"]
};

const hidden_classes=["alcohol_shop",
      "clothing_store",
      "grocery",
      "music",
      "shop",
	  "hairdresser",
	  "fast_food",
	  "ice_cream",
	  "car",
	  "pharmacy",
	  "bakery",
	  "cafe",
	  "restaurant",
	  "car_repair",
	  "office",
	  "laundry",
	  "doctors",
	  "dentist",
	  "lodging",
	  "butcher",
	  "shelter"
];
export class Geomap extends VectorTileLayer {
    constructor() {
        super({
			source: new VectorTileSource({
				maxZoom:10
			}),
            declutter: true,
            zIndex: 100,
        });

        this._features = [];
        this._layers = ["boundary", "globallandcover","place", "transportation_name", "aerodrome_label", "water_name", "poi"];

        // Initialize event listeners for the source
        this.on('sourceready', () => {
            const source = this.getSource();
			//source.setMaxZoom(10)
            source.on('tileloadstart', () => {
                this._features = [];
            });
        });

        // Fetch and apply the Mapbox style to the vector layer
        fetch(`https://tiles.behrah.com/mvt/style.json`)
            .then(response => response.json())
            .then(style => {
                applyStyle(this, style).then(() => {
                    this.changed();
                });
            })
            .catch(error => {
                console.error('Error fetching style:', error);
            });
    }

    setStyle(style) {
        super.setStyle((feature, resolution, onlyLayer) => {
			const prop=feature.getProperties();
			if (prop.layer=='poi' && (hidden_classes.indexOf(prop.class)>-1 || prop.subclass=="books")) return;
            const _style = typeof style === 'function' ? style(feature, resolution, onlyLayer) : style;
            if (_style) {
				if (this._layers.indexOf(prop.layer)<0){
					return;
				}
				if (this._features.indexOf(feature)<0)
                this._features.push(feature);
            }
			if (_style && this._layers.indexOf('place')<0){
				_style.forEach(s=>s.setText(null))
			}
            return _style;
        });
    }

    setLayers(layers) {
		this._layers=[];
		layers.forEach(l=>this._layers.push(...layers_group[l]));
		this.changed();
    }
	resetFeatures(){
		this._features=[];
	}
    getFeatures() {
        return this._features.map(f => {
            const feature = OLFeature(f);
            return feature
        });
	}
}

// Helper function to retrieve map features
export function getMapFeatures() {
    return _features.map(f => OLFeature(f));
}
