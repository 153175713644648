
    // Function to toggle fullscreen for a specific element
 export   function toggleFullscreen(element) {
        if (isElementInFullscreen(element)) {
            // Exit fullscreen mode if the element is already in fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        } else {
            // Request fullscreen mode for the element
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { // Firefox
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { // IE/Edge
                element.msRequestFullscreen();
            }
        }
    }

    // Function to check if an element is in fullscreen mode
    function isElementInFullscreen(element) {
        return (document.fullscreenElement === element ||
                document.mozFullScreenElement === element ||
                document.webkitFullscreenElement === element ||
                document.msFullscreenElement === element);
    }

    // Event listeners for fullscreen change events
    document.addEventListener('fullscreenchange', onFullscreenChange);
    document.addEventListener('mozfullscreenchange', onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', onFullscreenChange);
    document.addEventListener('msfullscreenchange', onFullscreenChange);

    // Fullscreen change handler
    function onFullscreenChange() {
        const isFullscreen = document.fullscreenElement || 
                             document.mozFullScreenElement || 
                             document.webkitFullscreenElement || 
                             document.msFullscreenElement;
		if (isFullscreen)
			document.querySelector('.btn-fullscreen').classList.add('selected');
		else
			document.querySelector('.btn-fullscreen').classList.remove('selected');
			
    }
