import Map from 'ol/Map.js';
import View from 'ol/View.js';
import ScaleLine from 'ol/control/ScaleLine.js';
import { intersects } from 'ol/extent';
import { Fill, Stroke, Text } from 'ol/style.js';
import { transformExtent } from 'ol/proj';
import { getCenter, getSize } from 'ol/extent';
import Tile from 'ol/source/tile';
import { Terrain } from './terrain.js';
import { Contour } from './contour.js';
import { Satellite } from './satellite.js';
import { Elevation } from './elevation.js';
import { Geomap } from './geomap.js';
import Graticule from 'ol/layer/Graticule.js';
import {filterFeatureCoordinates} from './utils.js'
import { InfoBox } from './infobox.js';
import {
    getSettings,
    setSettings
}
from './settings.js';
import featureStyler from './styler.js';




const Iran = transformExtent([43, 24.3, 64, 40.7], 'EPSG:4326', 'EPSG:3857');

const info = new InfoBox();
const geomap = new Geomap();
const terrain = new Terrain();
const satllite = new Satellite();
const contour = new Contour();
const elevation = new Elevation();
const graticule = new Graticule({
    showLabels: true,
    strokeStyle: new Stroke({
        color: 'rgba(255,120,0,0.9)',
        width: 2,
        lineDash: [0.5, 4],
    }),
    lonLabelPosition: 0.005,
    lonLabelStyle: new Text({
        font: '12px Tahoma',
        textBaseline: 'bottom',
        fill: new Fill({
            color: 'rgba(0,0,0,1)',
        }),
        stroke: new Stroke({
            color: 'rgba(255,255,255,1)',
            width: 3
        })
    }),
    latLabelPosition: 0.995,
    wrapX: false,
    zIndex: 150,
});

const mapLayers = [
    satllite,
    terrain,
    geomap,
    contour,
    elevation,
    graticule
];

let zoom = localStorage.getItem('zoom') || 6;
let center = JSON.parse(localStorage.getItem('center')) || getCenter(Iran);
const view = new View({
    center: center,
  //  maxZoom: 20,
    minZoom: 0,
    zoom: 4,
    extent: Iran,
    showFullExtent: true,
    enableRotation: false,
	constrainResolution: false,
	resolution: 1,

});

const map = new Map({
    target: 'map',
    controls: [
        new ScaleLine({
            text: true,
            bar: true,
        })
    ],
    layers: [],
    view: view,
});


view.on('change', () => {
    localStorage.setItem('zoom', view.getZoom());
    localStorage.setItem('center', JSON.stringify(view.getCenter()));
});
view.changed();

function updateMap() {
    const settings = map.get('settings');
    terrain.setMap(settings.background === 'terrain' ? map : null);
    satllite.setMap(settings.background === 'satllite' ? map : null);
    contour.setMap(settings.topography.includes('contour') ? map : null);
    geomap.setMap(settings.geomap.length > 0 ? map : null);
    geomap.setLayers(settings.geomap);
    graticule.setMap(settings.graticule.length>0? map : null);
    const div = map.getTargetElement();
    const canvas = div.querySelector('.ol-layers');
    canvas.style.filter = `saturate(${settings.saturation / 100})`;
}

document.addEventListener('toolbar', e => {
    const btn = e.detail;
    if (btn.id === 'infobox') {
        info.setMap(btn.classList.contains('selected') ? map : null);
    }
    if (btn.id === 'view-elevation') {
        elevation.setMap(btn.classList.contains('selected') ? map : null);
    }
});

map.once('postrender', () => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    map.set('settings', settings);
    updateMap();
});

document.addEventListener('settingsChanged', event => {
    map.set('settings', event.detail);
    updateMap();
});

export default map;

export function getMapFeatures(callback) {
	map.once('rendercomplete',e=>{
		const viewExtent = map.getView().calculateExtent(map.getSize());
		let features = geomap.getFeatures();
		features.push(...contour.getFeatures());

		callback(features);
	});
	contour.resetFeatures();
	geomap.resetFeatures();
	setSettings(getSettings());
	map.render();
}

featureStyler.setMap(map);
featureStyler.setInvalidLayers([geomap,contour])
