import { Select } from 'ol/interaction';
import { singleClick, pointerMove } from 'ol/events/condition';

class Selector extends Select {
  constructor(options) {
	
    const { layer, ...selectOptions } = options;

    if (!layer) {
      throw new Error('Layer must be provided');
    }

    const source = layer.getSource();
    super({
      condition: singleClick,
      ...selectOptions
    });

	this.setHitTolerance(5);
    this.layer = layer;
    this.source = source;

    this.handlePointerMove = this.handlePointerMove.bind(this);
  }

  setMap(map) {
    if (this.getMap()) {
      this.getMap().un('pointermove', this.handlePointerMove);
    }

    super.setMap(map);

    if (map) {
      map.on('pointermove', this.handlePointerMove);
    }
  }

  handlePointerMove(evt) {
    const hit = this.getMap().hasFeatureAtPixel(evt.pixel, {
      layerFilter: layer => layer === this.layer
    });
    this.getMap().getTargetElement().style.cursor = hit ? 'pointer' : '';
  }
}

export default Selector;
