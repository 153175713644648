import map from './map.js';
import Dialog from './dialog.js'
import Draw from 'ol/interaction/Draw.js';
import Select from 'ol/interaction/Select';
import Modify from 'ol/interaction/Modify';
import Transform from 'ol-ext/interaction/Transform';
import Selector from './selector.js';
import Toolbar from './toolbar.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {
    loadFile,
    saveFile,
    getDateTimeString
}
from './utils.js'
import {
    defaultStyle,
    modifyStyles,
    createStyles,
    customStyles
}
from './styles.js';

import {
    boundingExtent,
    getBottomLeft,
    getBottomRight,
    getTopLeft,
    getTopRight,
}
from 'ol/extent.js';

//import Styler from './styler.js';

import {
    Vector as VectorSource
}
from 'ol/source.js';
import {
    Vector as VectorLayer
}
from 'ol/layer.js';

import {
    LineString,
    Circle,
    Polygon
}
from 'ol/geom';
import {
    fromCircle
}
from 'ol/geom/Polygon';

import {
    fromUserCoordinate,
    getUserProjection,
}
from 'ol/proj.js';

const datafileheader = 'Topomap Drawings';

const drawSource = new VectorSource({
    wrapX: false
});

const styleFunction = (feature, caller = null) => {
    return customStyles(feature, caller)
};

export const drawLayer = new VectorLayer({
    source: drawSource,
    style: feature => {
        return customStyles(feature, 'layer');
    },
    zIndex: 500,
});

map.addLayer(drawLayer);

drawSource.on('change', e => {
    const features = drawSource.getFeatures();
    document.querySelectorAll('.btn-draw.not-empty').forEach(b => {
        b.disabled = features.length == 0
    })
});

let drawer = null,
modify = null,
selector = new Selector({
    layer: drawLayer,
    style: null,
    filter: (feature) => {
        let geom = feature.getGeometry().get('Name') || feature.getGeometry().getType();
        const modification = selector.get('modification');
        if (modification == 'modify' && (geom == 'Elipse' || geom == 'Rectangle'))
            return false;
        return true;
    },
});

selector.setActive(false);
map.addInteraction(selector);

let stylerOpen = false;
selector.on('select', e => {
    map.removeInteraction(modify);
    if (e.selected.length > 0) {
        let feature = e.selected[0];
        let geom = feature.getGeometry().get('Name') || feature.getGeometry().getType();
        const modification = selector.get('modification');
        if (modification === 'styler') {
            if (!stylerOpen) {
                stylerOpen = true;
                // let styler = new Styler(feature);
                // styler.onclose = feature => {
                    // stylerOpen = false;
                // }
                selector.getFeatures().clear();
            }
        } else if (modification === 'modify' || (modification == 'transform' && geom == 'Point')) {
            modify = new Modify({
                features: selector.getFeatures(),
                style: modifyStyles,
            });
            map.addInteraction(modify);
        } else {
            modify = new Transform({
                features: selector.getFeatures(),
                style: feature => {
                    return styleFunction(feature, 'transform');
                },
            });
            map.addInteraction(modify);
            modify.select(feature, true);
        }
    }
});

const createElipse = function (coordinates, geometry) {
    let center = coordinates[0];
    let last = coordinates[1];
    let dx = center[0] - last[0];
    let dy = center[1] - last[1];
    let radius = Math.sqrt(dx * dx + dy * dy);
    let circle = new Circle(center, radius);
    let polygon = fromCircle(circle, 64);
    polygon.scale(dx / radius, dy / radius);
    if (!geometry) {
        geometry = polygon;
    } else {
        geometry.setCoordinates(polygon.getCoordinates());
    }
    geometry.set('Name', 'Elipse');
    return geometry;
}

function createBox() {
    return function (coordinates, geometry, projection) {
        const extent = boundingExtent(
                /** @type {LineCoordType} */
                ([
                        coordinates[0],
                        coordinates[coordinates.length - 1],
                    ]).map(function (coordinate) {
                    return fromUserCoordinate(coordinate, projection);
                }), );
        const boxCoordinates = [
            [
                getBottomLeft(extent),
                getBottomRight(extent),
                getTopRight(extent),
                getTopLeft(extent),
                getBottomLeft(extent),
            ],
        ];
        if (geometry) {
            geometry.setCoordinates(boxCoordinates);
        } else {
            geometry = new Polygon(boxCoordinates);
        }
        const userProjection = getUserProjection();
        if (userProjection) {
            geometry.transform(projection, userProjection);
        }
        geometry.set('Name', 'Rectangle');
        return geometry;
    };
}

function draw(type) {
    selector.setActive(false);
    if (!type || type == 'none')
        return;

	const featureType=type == 'draw-point' ? 'Point' : type == 'draw-circle' ? 'Circle' : type == 'draw-rect' ? 'Rectangle' : type == 'draw-poly' ? 'Polygon' : type == 'draw-line' ? 'LineString' : 'Point';
    drawer = new Draw({
        source: drawSource,
        type: featureType=='Rectangle'?'Circle':featureType,
        geometryFunction: featureType == 'Rectangle' ? createBox() : featureType == 'Circle' ? createElipse : null,
        style: feature => {
            return styleFunction(feature, 'draw');
        },
    });
	drawer.once('drawstart',e=>{
		e.feature.set('_type',featureType);
		//e.feature.set('_styles',defaultStyle);
	})
	drawer.on('drawend',e=>{
		e.feature.set('_type',featureType);
	})
    map.addInteraction(drawer);
}

function clearInteractions() {
    selector.getFeatures().clear();
    map.removeInteraction(drawer);
    map.removeInteraction(modify);
    drawer = null;
    modify = null;
}

document.addEventListener('toolbar', e => {
    const btn = e.detail,
    selected = btn.classList.contains('selected'),
    btn_draw = btn.classList.contains('btn-draw');
    if (!btn_draw)
        return;

    document.querySelectorAll('.btn-ruler.selected').forEach(b => {
        b.classList.remove('selected')
        let event = new CustomEvent("toolbar", {
            "detail": b
        });
        document.dispatchEvent(event);
    });
    btn.classList.toggle('selected', selected);
    clearInteractions();
    switch (btn.id) {
    case 'draw':
        if (!selected) {
            document.querySelectorAll('.btn-draw.selected').forEach(b => b.click());
            draw('none');
        }

        document.querySelectorAll('.btn-draw').forEach(b => {
            if (b !== btn)
                b.classList.toggle('d-none', !selected)
        });
        break;
    case 'clear-draw':
        new Dialog('error-dialog', 'توجه', 'آیا مایلید کلیه ترسیمات روی نقشه را پاک نمایید؟', [{
                    "text": 'خیر',
                    "class": "btn btn-primary",
                    "data-bs-dismiss": "modal"
                }, {
                    "text": 'بله',
                    "id": 'ok',
                    "class": "btn btn-outline-danger",
                    "data-bs-dismiss": "modal"
                },
            ]).onclick = btn => {
            if (btn.id == 'ok')
                drawSource.clear();
        };
        break;
    case 'load-draw':
        (async() => {
            const file = await loadFile();
			if (file){
				drawSource.clear();
				const features=new GeoJSON().readFeatures(file.features);
				drawSource.addFeatures(features);
				localStorage.setItem('settings',file.settings);
				map.getView().animate({zoom:parseFloat(file.zoom),center:JSON.parse(file.center),duration:500});
				let event = new CustomEvent("updatemap");
				document.dispatchEvent(event);
			}
        })();
        break;
    case 'save-draw':
        new Dialog('default-dialog', 'ذخیره نقشه و ترسیمات', `<div class="">
		<label class="form-label">نام فایل</label>
		<input type="text" name="filename" class="form-control">
		<p class="text-muted">فایل با پسوند <span class="fw-bold ltr">.topomap</span> و بصورت پیشفرض در مسیر دانلودها ذخیره میشود.</p>
		</div>`, [{
                    "text": 'ذخیره',
                    "id": 'ok',
                    "class": "btn btn-primary",
                    "data-bs-dismiss": "modal"
                }, {
                    "text": 'صرفنظر',
                    "class": "btn btn-outline-dark",
                    "data-bs-dismiss": "modal"
                },
            ]).onclick = function (btn) {
            if (btn.id != 'ok')
                return;
            const values = this.getValues();
            const filename = values.filename.trim() || `draw-${getDateTimeString()}`;
			const features=drawSource.getFeatures();
			const data={
				settings:localStorage.getItem('settings'),
				zoom:localStorage.getItem('zoom'),
				center:localStorage.getItem('center'),
				features:new GeoJSON().writeFeatures(features)
			};
            saveFile(filename + '.topomap',data, datafileheader);
        };
        break;
    case 'transform':
    case 'modify':
    case 'styler':
        selector.set('modification', selected ? btn.id : '');
        selector.setActive(selected);
        break;

    case 'draw-point':
    case 'draw-circle':
    case 'draw-rect':
    case 'draw-poly':
    case 'draw-line':
        if (selected)
            draw(btn.id);
        break;
    }
});

const drawToolbar = new Toolbar("toolbar toolbar-vertical toolbar-top toolbar-right level-2", {
    toolbar: [
        [{
                "text": '<i class="icon-move"></i>',
                "id": 'transform',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'تنظیم نحوه نمایش نقشه'
            }, {
                "text": '<i class="icon-select-node"></i>',
                "id": 'modify',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'تنظیم نحوه نمایش نقشه'
            }, {
                "text": '<i class="icon-palette"></i>',
                "id": 'styler',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'تنظیم نحوه نمایش نقشه'
            }, ],
        [{
                "text": '<i class="icon-location"></i>',
                "id": 'draw-point',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'تنظیم نحوه نمایش نقشه'
            }, {
                "text": '<i class="icon-circle"></i>',
                "id": 'draw-circle',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'جستجوی موقعیت جغرافیایی'
            }, {
                "text": '<i class="icon-rectangle"></i>',
                "id": 'draw-rect',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'جستجوی موقعیت جغرافیایی'
            }, {
                "text": '<i class="icon-polygon"></i>',
                "id": 'draw-poly',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'ترسیم شکل'
            }, {
                "text": '<i class="icon-line"></i>',
                "id": 'draw-line',
                "data-radio": 'draw',
                "class": "btn-draw d-none toggle",
                "title": 'ترسیم شکل'
            }, ],
    ]
});
map.addControl(drawToolbar);
