import '/assets/css/bootstrap.scss';
import 'ol/ol.css';

import '/assets/css/styles.scss';

import settings from './settings.js';
import map from './map.js';
import Toolbar from './toolbar.js';
import Search from './search';
import {
    toggleFullscreen
}
from './fullscreen.js';
import './draw.js';
import './ruler.js';
import mapExport from './export.js';

window.mapSettings = settings;

map.addControl(new Toolbar("toolbar toolbar-right toolbar-top", {
        toolbar: [
            [{
                    "text": '<i class="icon-setting"></i>',
                    "id": 'open-sidebar',
                    "class": 'selected',
                    "title": 'تنظیم نحوه نمایش نقشه',
                    "onclick": "window.mapSettings()"
                },
            ],
            [{
                    "text": '<i class="icon-search"></i>',
                    "id": 'search-points',
                    onclick: search,
                    "title": 'جستجوی موقعیت جغرافیایی'
                },
            ],
            [{
                    "text": '<i class="icon-remove"></i>',
                    "id": 'clear-draw',
                    "class": 'btn-draw d-none not-empty',
                    "title": '',
                    disabled: true
                }, {
                    "text": '<i class="icon-pencil"></i>',
                    "id": 'draw',
                    "data-toggle": true,
                    "class": 'btn-draw',
                    "title": '',
                },
            ],
        ]
    }));

map.addControl(new Toolbar("toolbar toolbar-center toolbar-bottom", {
        toolbar: [
            [{
                    "text": '<i class="icon-camera"></i>',
                    "class": '',
                    "title": '',
                    "onclick": exportMap
                }, {
                    "text": '<i class="icon-floppy"></i>',
                    "id": 'save-draw',
                    "title": ''
                }, {
                    "text": '<i class="icon-folder"></i>',
                    "id": 'load-draw',
                    "title": '',
                },
            ]
        ]
    }));
map.addControl(new Toolbar("toolbar toolbar-left toolbar-top toolbar-vertical", {
        toolbar: [
            [{
                    "text": '<i class="icon-fullscreen"></i>',
                    "id": 'fullscreen',
                    "data-toggle": true,
                    "class": 'btn-fullscreen ',
                    "title": 'نمایش تمام صفحه نقشه',
                    onclick: () => {
                        const element = document.getElementById('map-container');
                        if (!element)
                            return false;
                        toggleFullscreen(element)
                    }
                },
            ],
            [{
                    "text": '<i class="icon-plus"></i>',
                    "id": 'zoomup',
                    "title": 'زوم بیشتر',
                    onclick: () => {
						        map.getInteractions().forEach(interaction => interaction.setActive(false));

                        const view = map.getView()
                            //      zoom = view.getZoom();
                            //     view.setZoom(Math.floor(zoom + 1));
                            let zoomFactor = 2; // Scale factor
                        let currentResolution = view.getResolution();
                        view.setResolution(currentResolution / zoomFactor);

                    }
                }, {
                    "text": '<i class="icon-minus"></i>',
                    "id": 'zoondn',
                    "title": 'زوم کمتر',
                    onclick: () => {
                        const view = map.getView()
                            //                  zoom = view.getZoom();
                            //                   view.setZoom(Math.ceil(zoom - 1));
                            let zoomFactor = 2; // Scale factor
                        let currentResolution = view.getResolution();
                        view.setResolution(currentResolution * zoomFactor);
                    }
                },
            ],
            [{
                    "text": '<i class="icon-globe-outline"></i>',
                    "id": 'infobox',
                    "data-toggle": true,
                    "class": '',
                    "title": 'موقعیت یاب جرافیایی'
                }, {
                    "text": '<i class="icon-filter_hdr"></i>',
                    "id": 'view-elevation',
                    "data-toggle": true,
                    "class": '',
                    "title": 'اندازه گیری ارتفاعات'
                }, {
                    "text": '<i class="icon-ruler"></i>',
                    "id": 'length',
                    "class": 'btn-ruler toggle',
                    "data-radio": 'ruler',
                    "title": 'اندازه گیری فاصله'
                }, {
                    "text": '<i class="icon-ruler2"></i>',
                    "id": 'area',
                    "class": 'btn-ruler toggle',
                    "data-radio": 'ruler',
                    "title": 'اندازه گیری مساحت'
                },
            ],
        ]
    }));

function search() {
    new Search(map);
}

function exportMap(e) {
    new mapExport();
}
