import 'tom-select/dist/css/tom-select.bootstrap5.css';
import './search.css';

import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';

import GeoJSON from 'ol/format/GeoJSON.js';
import {
    Vector as VectorSource
}
from 'ol/source.js';
import {
    Vector as VectorLayer
}
from 'ol/layer.js';
import {
    toLonLat,
    transformExtent
}
from 'ol/proj';
import {
    Fill,
    Icon,
    Stroke,
    Circle,
    Style,
    Text
}
from 'ol/style.js';

import Dialog from './dialog.js';
import {
    boundingExtent
}
from 'ol/extent';

import {
    hdmsToLatLng,
    degToLatLng,
    utmToLatLng,
    lonlatToUTM,
    toClipboard,
    getOverpassFeature
}
from './utils.js';
import {
    toStringHDMS,
    toStringXY
}
from 'ol/coordinate.js';
import TomSelect from 'tom-select/dist/js/tom-select.base.js';
import {
    fromLonLat
}
from 'ol/proj';

const html = `
<div class="d-flex justify-content-between flex-wrap mb-3">
    <label>
        <input type="radio" name="search-type" data-target="#loc" class=""  value="loc" checked>
        نام مکان
    </label>
    <label>
        <input type="radio" name="search-type" data-target="#deg" class="" value="deg">
        Deg
    </label>
    <label>
        <input type="radio" name="search-type" data-target="#hdms" class="" value="dms">
        DMS
    </label>
    <label>
        <input type="radio" name="search-type" data-target="#utm" class="" value="utm">
        UTM
    </label>
</div>
<div id="loc" class="text-holder show" dir="rtl">
    <div class="mb-2"><select class="form-control loc-select rtl" id="location" name="location" dir="rtl" placeholder="نام مکان مورد نظر را بنویسید"></select></div>
    <p class="text-muted small">جستجو فقط در محدوده نقشه انجام میشود.</p>
</div>
<div id="deg" class="text-holder">
    <div class="mb-2"><input type="text" class="form-control ltr" name="deg"></div>
    <p class="text-muted small">طول و عرض جغرافیایی lat, lon</p>
</div>
<div id="hdms" class="text-holder">
    <div class="mb-2"><input type="text" class="form-control ltr" name="hdms"></div>
    <p class="text-muted small">موقعیت طول و عرض جعرافیایی درجه/دقیقه/ثانیه</p>
</div>
<div id="utm" class="text-holder">
    <div class="mb-2"><input type="text" class="form-control ltr" name="utm"></div>
    <p class="text-muted small" >منطقه/شمالی‌جنوبی/عرض/طول</p>
</div>
<div class="results d-flex justify-content-between flex-column">
    <div class="result" name="deg"></div>
    <div class="result my-3" name="dms"></div>
    <div class="result" name="utm"></div>
</div>
<div class=""><label><input type="checkbox" name="clear-on-exit" value="yes"> جسجوی قبلی پاک شوند.</lable></div>
`;

const formats = ['deg', 'dms', 'utm'];

const searchLayer = new VectorLayer({
    source: new VectorSource(),
    style: new Style({
		fill: new Fill({
			color:'rgba(255,255,255,0.05)'
		}),
        stroke: new Stroke({
            color: '#cf0000',
            width: 3,
            lineDash: [7, 7]
        }),
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: 'rgba(255,255,255,0.5)',
            }),
            stroke: new Stroke({
                color: '#cf0000',
                width: 2
            }),
        }),
    }),
    zIndex: 200,
});

export default class Search {
    constructor(map) {

        const search = this;
        search.value = false;
        search.type = 'loc'
            const dlg = new Dialog('', 'جستجوی مکان', html, [{
                        'text': 'تایید',
                        id: 'ok',
                        'class': 'btn btn-primary',
                        'data-bs-dismiss': 'modal'
                    }, {
                        'text': 'بستن',
                        'class': 'btn btn-dark',
                        'data-bs-dismiss': 'modal'
                    },
                ]);
        dlg.onclick = btn => {
            searchLayer.setMap(map);
            const source = searchLayer.getSource();
            if (search.element.querySelector("input[name='clear-on-exit']:checked"))
                source.clear();

            if (btn.id != 'ok' || !map || !search.value)
                return;
            const view = map.getView();
            if (search.type == 'loc' && search.feature && search.feature.extent) {
                const bbox = search.feature.extent,
                extent = boundingExtent([fromLonLat([bbox[0], bbox[1]]), fromLonLat([bbox[2], bbox[3]])]);
                view.fit(extent, {
                    duration: 1000
                });
                if (search.feature.osm_type == 'R' || search.feature.osm_type == 'W')
                    getOverpassFeature(search.feature.osm_type == 'R' ? 'rel' : 'way', search.feature.osm_id, geojson => {
                        const features = new GeoJSON({
                            dataProjection: 'EPSG:4326',
                            featureProjection: 'EPSG:3857'
                        }).readFeatures(geojson);
                        source.addFeatures(features)
                    })
            } else {
                view.animate({
                    center: fromLonLat(search.value),
                    zoom: Math.min(view.getZoom(), 10),
                    duration: 500
                });
                source.addFeature(new Feature(new Point(fromLonLat(search.value))))
            }
        };
        const element = dlg.element();
        element.addEventListener('change', e => {
            const elm = e.target;
            if (elm.name == 'search-type') {
                element.querySelectorAll('.text-holder').forEach(e => {
                    e.classList.remove('show');
                });
                const target = this.element.querySelector(elm.getAttribute('data-target'));
                if (target) {
                    target.classList.add('show');
                    const input = target.querySelector('input');
                    search.setValue(search.toDegree(input.value, input.name));
                    search.type = input.value;
                }
            }
        });
        element.addEventListener('input', e => {
            const input = e.target;
            input.classList.remove('is-valid', 'is-invalid');
            if (formats.indexOf(input.name) > -1) {
                const lonlat = this.toDegree(input.value, input.name);
                if (input.value.trim() != '')
                    input.classList.add(lonlat === false ? 'is-invalid' : 'is-valid');
                search.setValue(lonlat);
            }
        });
        element.addEventListener('click', e => {
            const btn = e.target;
            if (btn.tagName == 'BUTTON' && btn.classList.contains('copy')) {
                toClipboard(btn.getAttribute('data-value'), res => {
                    btn.classList.add('bg-dark');
                    btn.style.filter = 'invert(100%)';
                    setTimeout(() => {
                        btn.classList.remove('bg-dark');
                        btn.style.filter = '';
                    }, 500);
                });
            }
        });
        element.addEventListener("shown.bs.modal", event => {
            let controller; // Variable to hold the current AbortController

            const tomy=new TomSelect("#location", {
                onChange: value => {
                    search.feature = null;
                    if (value != '') {
                        const feature = JSON.parse(value);
                        search.feature = feature;
                        search.setValue(feature.coordinates);
                    }
                },
                presist: false,
				create:false,
                openOnFocus: false,
                valueField: 'value',
                labelField: 'name',
                searchField: ['name'], // 'county', 'state', 'country']
                openOnFocus: true,
                closeAfterSelect: true,
                score: function (search) {
                    var score = this.getScoreFunction(search);
                    return function (item) {
                        const query = search.toLowerCase();
                        const name = item.name.toLowerCase();
                        const containsQuery = name.includes(query);
                        return containsQuery ? score(item) + 1 : 1;
                    }
                },
                shouldLoad: function (query) {
                    return query.length > 1;
                },
                loadThrottle: 1000,
                load: function (query, callback) {
                    if (!query.length)
                        return callback();
                    if (controller) {
                        controller.abort();
                    }

                    // Create a new AbortController instance
                    controller = new AbortController();
                    const view = map.getView();
                    const signal = controller.signal;
                    const bbox = transformExtent(view.calculateExtent(), 'EPSG:3857', 'EPSG:4326');
                    const zoom = Math.floor(view.getZoom());
                    const center = toLonLat(view.getCenter());
                    let layers = 'state,county,city';
                    if (zoom > 10 && zoom< 13){
                        layers = 'county,city,district,locality&bbox=' + bbox.toString().replaceAll(' ', '');
                    }

                    if (zoom >= 13){
                        layers = 'district,locality,street&bbox=' + bbox.toString().replaceAll(' ', '');
                    }

                    var url = `https://behrah.com/search.php?z=${zoom}&c=${center.toString().replaceAll(' ','')}&l=${layers}&q=${encodeURIComponent(query)}`;
                    fetch(url, {
                        signal
                    })
                    .then(response => response.json())
                    .then(json => {
						tomy.clear();
                        callback(json.features.map(feature => {
                                return {
                                    ...feature.properties,
                                    value: JSON.stringify({
                                        ...feature.geometry,
                                        ...feature.properties
                                    })
                                };
                            }));
                    }).catch(() => {
                        callback();
                    });
                },
                render: {
                    option: function (item, escape) {
                        const icon = (item.osm_type == 'N') ? 'ico-node' : (item.osm_type == 'W') ? 'ico-way' : (item.osm_type == 'R') ? 'ico-rel' : '';
                        let text = '';
                        if (item.county)
                            text += '، ' + item.county;
                        if (item.state)
                            text += '، ' + item.state;
                        return `
                            <div class="py-2 d-flex">
                                    <div class="mb-1 no-wrap">
                                        <span class="h5"><div class="mx-1 ico ${icon}"></div>${escape(item.name)}</span>
                                        <span class="text-muted"> ${escape(text)}</span>
                                    </div>
                            </div>`;
                    },
                    item: function (item, escape) {
                        const icon = (item.osm_type == 'N') ? 'ico-node' : (item.osm_type == 'W') ? 'ico-way' : (item.osm_type == 'R') ? 'ico-rel' : '';
                        let text = '';
                        if (item.county)
                            text += '، ' + item.county;
                        if (item.state)
                            text += '، ' + item.state;
                        return `<div class="item"><div class="mx-1 ico ${icon}"></div>${escape(item.name)} ${escape(text)}</div>`;
                    }
                }
            });
        });
        search.element = element;
        search.setValue(false);
    }

    toDegree(str, format) {
        str = str.toUpperCase().trim();
        return (format == 'dms') ? hdmsToLatLng(str) :
        (format == 'deg') ? degToLatLng(str) :
        (format == 'utm') ? utmToLatLng(str) : null;
    }
    setValue(lonlat) {
        this.value = lonlat;
        this.element.querySelectorAll('.results .result').forEach(result => {
            let name = result.getAttribute('name'),
            value = '-';
            if (lonlat && lonlat !== false) {
                if (name == 'deg')
                    value = toStringXY(lonlat.reverse(), 5);
                if (name == 'dms')
                    value = toStringHDMS(lonlat.reverse());
                if (name == 'utm')
                    value = lonlatToUTM(lonlat).text;
            }
            result.innerHTML = lonlat == null || lonlat === false ? `<span class="value"><b>${name.toUpperCase()} :</b> -` :
`<span class="value"><b>${name.toUpperCase()} :</b> ${value}</span><button type="button" class="btn copy" data-value="${value}"></button>`;
        });
    }
}
