import map from './map.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import Draw from 'ol/interaction/Draw.js';
import Modify from 'ol/interaction/Modify.js';
import { customStyles, modifyStyles } from './styles.js';

const rulerSource = new VectorSource();
const rulerLayer = new VectorLayer({
    source: rulerSource,
    style: feature => customStyles(feature, 'ruler'),
    zIndex: 200
});

let drawer = null;
const modify = new Modify({
    source: rulerSource,
    style: modifyStyles,
    active: false
});

map.addInteraction(modify);

function setDraw(type) {
    map.removeInteraction(drawer);
    map.removeInteraction(modify);

    if (type === '') {
        rulerSource.clear();
        rulerLayer.setMap(null);
        return;
    }

    rulerLayer.setMap(map);
    drawer = new Draw({
        source: rulerLayer.getSource(),
        style: feature => customStyles(feature, 'ruler'),
        type: type === 'length' ? 'LineString' : 'Polygon',
    });

    map.addInteraction(drawer);
    drawer.on('drawend', () => {
        map.addInteraction(modify);
    });
}

document.addEventListener('toolbar', e => {
    const btn = e.detail;
    if (!btn.classList.contains('btn-ruler')) return;

    const selected = btn.classList.contains('selected');
    document.querySelectorAll('.btn-draw.selected').forEach(b => {
        b.classList.remove('selected');
        let event = new CustomEvent("toolbar", { "detail": b });
        document.dispatchEvent(event);
    });

    btn.classList.toggle('selected', selected);

    if (btn.classList.contains('selected')) {
        setDraw(btn.id);
    } else {
        setDraw('');
    }
});
