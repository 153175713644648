import './infobox.css';
import Control from 'ol/control/Control.js';
import {transform} from 'ol/proj';
import {toStringHDMS,toStringXY} from 'ol/coordinate.js';
import {unByKey} from 'ol/Observable';
import {lonlatToUTM,toClipboard,isMobileDevice} from './utils.js';

const div=document.createElement('div');
div.className='ol-unselectable ol-control infobox';
div.innerHTML=`
	<div class="info-body"></div>
	<div class="d-flex justify-content-evenly mt-1">
		<input type="radio" class="btn-check" name="infotype" id="btn-deg" autocomplete="off" value="deg" checked>
		<label class="pos-label" for="btn-deg">DEG</label>
		<input type="radio" class="btn-check" name="infotype" id="btn-utm" autocomplete="off" value="utm">
		<label class="pos-label" for="btn-utm">UTM</label>
		<input type="radio" class="btn-check" name="infotype" id="btn-hms" autocomplete="off" value="hdms">
		<label class="pos-label" for="btn-hms">HDMS</label>
	</div>
	<div class="crosshair"></div>
`;
let timer=null;
function utmZoneFromLatLng(lat, lon) {
  // Special Cases for Norway & Svalbard
  if (lat > 55 && lat < 64 && lon > 2 && lon < 6) {
    return 32;
  }
  if (lat > 71 && lon >= 6 && lon < 9) {
    return 31;
  }
  if (lat > 71 && ((lon >= 9 && lon < 12) || (lon >= 18 && lon < 21))) {
    return 33;
  }
  if (lat > 71 && ((lon >= 21 && lon < 24) || (lon >= 30 && lon < 33))) {
    return 35;
  }
  // Rest of the world
  if (lon >= -180 && lon <= 180) {
    return (Math.floor((lon + 180) / 6) % 60) + 1;
  }
	return 'Error';
}

export class InfoBox extends Control{
	constructor(){
		super({
			element:div
		})
		this.body=div.querySelector('.info-body');
		this._coordinate=[0,0];
		
		this.handlePointer = this.handlePointer.bind(this); // Bind handlePointer
		this.updateInfo = this.updateInfo.bind(this); // Bind handlePointer
		this.infotype='deg';
		this.eventsKey=[];
		const that=this;
		div.addEventListener('change',e=>{
			const elm=e.target;
			if (elm.name=='infotype') this.infotype=elm.value;
			that.updateInfo()
		});
	}
	
	setMap(map){
		super.setMap(map);
		unByKey(this.eventsKey);
		if (!map) return;
				
		const that=this;
		const view=map.getView();
		this._coordinate=view.getCenter();
		
		this.eventsKey=[
			map.on('click',e=>{
				toClipboard(this.getString(),result=>{
					console.log(result);
				});
			}),
			isMobileDevice()?view.on('change:center',e=>{
				that._coordinate=view.getCenter();
				that.handlePointer();
			}):map.on('pointermove',e=>{
				that._coordinate=e.coordinate;
				that.handlePointer();
			})
		];
		this.updateInfo()
	}

	handlePointer(){
		clearTimeout(this._timer);
		this._timer=setTimeout(this.updateInfo,5);
	}
	
	updateInfo(){
		this.body.innerHTML=this.getString()
	}
	getString(){
		const coord=this._coordinate;
		const lonlat=transform([coord[0],coord[1]],'EPSG:3857','EPSG:4326');
		return (this.infotype=='hdms')?toStringHDMS(lonlat):
		(this.infotype=='utm')?lonlatToUTM(lonlat).text:
		toStringXY([lonlat[1],lonlat[0]],5);
	}
}