import TileLayer from 'ol/layer/WebGLTile.js';
import XYZ from 'ol/source/XYZ.js';
import Control from 'ol/control/Control.js';
import Overlay from 'ol/Overlay.js';
import { unByKey } from 'ol/Observable';
import './elevation.css';

const min_elevation_level = 0;
const html = `
<button type="button" class="btn-close position-absolute top-0 start-0 m-1"></button>
<div class="row">
    <div class="col-md-6">
        <label class="d-block mb-1">حداکثر ارتفاع</label>
        <input class="form-control" type="number" min="${min_elevation_level+1}" value="${min_elevation_level+1}" max="10000" step="1" id='max-level'>
    </div>
    <div class="col-md-6">
        <label class="d-block mb-1">حداقل ارتفاع</label>
        <input class="form-control" type="number" min="${min_elevation_level}" value="${min_elevation_level}" max="10000" step="1" id='min-level'>
    </div>
</div>
`;

const elevation = [
    '+',
    -10000,
    ['*', 0.1 * 255 * 256 * 256, ['band', 1]],
    ['*', 0.1 * 255 * 256, ['band', 2]],
    ['*', 0.1 * 255, ['band', 3]],
];

export class Elevation extends TileLayer {
    constructor() {
        const source = new XYZ({
            url: 'https://tiles.behrah.com/dem/{z}/{x}/{y}.webp',
            maxZoom: 14,
            tileSize: 512,
            interpolate: false,
        });

        super({
            opacity: 0.5,
            source: source,
            style: {
                variables: {
                    minLevel: min_elevation_level,
                    maxLevel: 1,
                },
                color: [
                    'case',
                    ['<=', ['var', 'minLevel'], 0],
                    ['case',
                        ['<=', elevation, ['var', 'maxLevel']],
                        [255, 212, 120, 1], // RGBA color for in-range elevation
                        [0, 0, 0, 0] // RGBA color for out-of-range elevation (transparent)
                    ],
                    ['case',
                        ['all', ['>=', elevation, ['var', 'minLevel']], ['<=', elevation, ['var', 'maxLevel']]],
                        [255, 212, 120, 1], // RGBA color for in-range elevation
                        [0, 0, 0, 0] // RGBA color for out-of-range elevation (transparent)
                    ]
                ],
            },
			zIndex:50,
        });

        this.hlayer = new TileLayer({
            source: source,
            opacity: 0,
			zIndex:0
        });

        const div = document.createElement('div');
        div.className = 'elevation-levels';
        div.innerHTML = html;
        const controller = new Control({ element: div });
        this._controller = controller;
        this._events = null;

        div.addEventListener('click', (e) => {
            if (e.target.classList.contains('btn-close'))
                controller.setMap(null);
        });

        div.addEventListener('change', (e) => {
            const min = div.querySelector('#min-level');
            const max = div.querySelector('#max-level');
			
            let min_value = parseInt(min.value) || min_elevation_level;
            let max_value = parseInt(max.value) || min_elevation_level;

            if (e.target.id === 'max-level')
                max_value = Math.min(10000, Math.max(min_value, max_value));
            if (e.target.id === 'min-level')
                min_value = Math.max(min_elevation_level, Math.min(max_value, min_value));

            min.value = min_value;
            max.value = max_value;

            min.max = max_value;
            max.min = min_value;

            this.setLevel(max_value, min_value);
			if (e.target===min)	min.select();
			if (e.target===max)	max.select();
        });
        // Create an overlay for displaying elevation information
        this.overlay = new Overlay({
            element: document.createElement('div'),
            offset: [0,-10],
            positioning: 'bottom-center',
        });
        this.overlay.getElement().className = 'elevation-tooltip';
	}

    setLevel(maxLevel, minLevel = 0) {
        this.updateStyleVariables({
            minLevel: minLevel,
            maxLevel: maxLevel
        });
    }

    setMap(map) {
        super.setMap(map);
        this.hlayer.setMap(map);
		this.overlay.setMap(map);

        this._controller.setMap(map);
        unByKey(this._events);
        if (!map) return;

        this._events = map.on('pointermove', (e) => {
            const pixel = map.getEventPixel(e.originalEvent);
            const eleValue=this.getElevationAtPixel(pixel);
			
			if (eleValue!==false)
				this.showElevationTooltip(e.coordinate, eleValue);
        });
    }

    getElevationAtPixel(pixel) {
        const data = this.hlayer.getData(pixel);
        if (!data) return false;

        // Decode elevation from RGB data
        return -10000 + Math.round(
            ((0.1 * 255 * 256 * 256 * data[0]) + 
            (0.1 * 255 * 256 * data[1]) + 
            (0.1 * 255 * data[2])) / 255
        );
    }
	showElevationTooltip(coordinate, elevation) {
        const tooltip = this.overlay.getElement();
        tooltip.innerHTML = elevation<0?0:elevation;
        this.overlay.setPosition(coordinate);
    }
}
