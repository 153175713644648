import {
    Fill,
    Stroke,
    Style,
    Text,
    Icon,
    Circle,
    RegularShape
}
from 'ol/style';
import {
    LineString,
    Point,
    Polygon
}
from 'ol/geom';
import {
    toStringXY
}
from 'ol/coordinate.js';

import {
    getArea,
    getLength,
    getDistance
}
from 'ol/sphere.js';

import {
    formatLen,
    formatLength,
    formatArea
}
from './utils';

import {
    fromLonLat,
    toLonLat,
}
from 'ol/proj.js';


// Function to create a Fill style
function createFill(fill) {
    return fill ? new Fill({
        color: fill.color ?? undefined
    }) : undefined;
}

// Function to create a Stroke style
function createStroke(stroke) {
    return (stroke && stroke.width > 0) ? new Stroke({
        color: stroke.color ?? undefined,
        width: stroke.width ?? undefined,
        lineCap: stroke.lineCap ?? undefined,
        lineJoin: stroke.lineJoin ?? undefined,
        lineDash: stroke.lineDash ?? undefined,
        lineDashOffset: stroke.lineDashOffset ?? undefined,
        miterLimit: stroke.miterLimit ?? undefined
    }) : undefined;
}

// Function to create a Text style
function createText(text) {
    return text ? new Text({
        font: text.font ?? undefined,
        maxAngle: text.maxAngle ?? undefined,
        offsetX: text.offsetX ?? undefined,
        offsetY: text.offsetY ?? undefined,
        overflow: text.overflow ?? undefined,
        placement: text.placement ?? undefined,
        scale: text.scale ?? undefined,
        rotateWithView: text.rotateWithView ?? undefined,
        rotation: text.rotation ?? undefined,
        text: text.text ?? undefined,
        textAlign: text.textAlign ?? undefined,
        textBaseline: text.textBaseline ?? undefined,
        fill: createFill(text.fill ?? null),
        stroke: createStroke(text.stroke ?? null),
        backgroundFill: createFill(text.backgroundFill ?? null),
        backgroundStroke: createStroke(text.backgroundStroke ?? null),
        padding: text.padding ?? undefined
    }) : undefined;
}

// Function to create an Icon style
function createIcon(icon) {
    return icon ? new Icon({
        anchor: icon.anchor ?? undefined,
        anchorOrigin: icon.anchorOrigin ?? undefined,
        anchorXUnits: icon.anchorXUnits ?? undefined,
        anchorYUnits: icon.anchorYUnits ?? undefined,
        color: icon.color ?? undefined,
        crossOrigin: icon.crossOrigin ?? undefined,
        img: icon.img ?? undefined,
        imgSize: icon.imgSize ?? undefined,
        offset: icon.offset ?? undefined,
        offsetOrigin: icon.offsetOrigin ?? undefined,
        opacity: icon.opacity ?? undefined,
        scale: icon.scale ?? undefined,
        snapToPixel: icon.snapToPixel ?? undefined,
        rotateWithView: icon.rotateWithView ?? undefined,
        rotation: icon.rotation ?? undefined,
        size: icon.size ?? undefined,
        src: icon.src ?? undefined,
        displacement: icon.displacement ?? undefined
    }) : undefined;
}

// Function to create a Circle style
function createCircle(circle) {
    return circle ? new Circle({
        radius: circle.radius ?? undefined,
        fill: createFill(circle.fill ?? null),
        stroke: createStroke(circle.stroke ?? null),
        displacement: circle.displacement ?? undefined,
        scale: circle.scale ?? undefined,
        rotation: circle.rotation ?? undefined,
        rotateWithView: circle.rotateWithView ?? undefined,
    }) : undefined;
}

// Function to create a RegularShape style
function createShape(shape) {
    return shape ? new RegularShape({
        points: shape.points ?? undefined,
        radius: shape.radius ?? undefined,
        radius1: shape.radius1 ?? undefined,
        radius2: shape.radius2 ?? undefined,
        angle: shape.angle ?? undefined,
        fill: createFill(shape.fill ?? null),
        stroke: createStroke(shape.stroke ?? null),
        displacement: shape.displacement ?? undefined,
        scale: shape.scale ?? undefined,
        rotation: shape.rotation ?? undefined,
        rotateWithView: shape.rotateWithView ?? undefined
    }) : undefined;
}
// Helper function to create a geometry from an object
function createGeometry(geometry) {
    if (!geometry)
        return undefined;
    const {
        type,
        coordinates
    } = geometry;
    switch (type) {
    case 'Point':
        return new Point(coordinates);
    case 'LineString':
        return new LineString(coordinates);
    case 'Polygon':
        return new Polygon(coordinates);
    default:
        return undefined;
    }
}

// Function to create a Style object
function createStyle(style) {
    if (!style)
        return null;
    return new Style({
        fill: createFill(style.fill ?? null),
        stroke: createStroke(style.stroke ?? null),
        text: createText(style.text ?? null),
        image: (!style.image) ? undefined : (style.image.icon) ? createIcon(style.image.icon) : (style.image.circle) ? createCircle(style.image.circle) : (style.image.shape) ? createShape(style.image.shape) : undefined,
        zIndex: style.zIndex ?? undefined,
        geometry: style.geometry ? createGeometry(style.geometry) : undefined,
        renderer: style.renderer ?? undefined
    });
}

export function createStyles(styles) {
    if (Array.isArray(styles)) {
        return styles.map(createStyle);
    } else {
        return createStyle(styles);
    }
}

// Function to extract properties from a Fill object
function extractFill(fill) {
    if (!fill)
        return undefined;
    return {
        color: fill.getColor() ?? undefined
    };
}

// Function to extract properties from a Stroke object
function extractStroke(stroke) {
    if (!stroke)
        return undefined;
    return {
        color: stroke.getColor() ?? undefined,
        width: stroke.getWidth() ?? undefined,
        lineCap: stroke.getLineCap() ?? undefined,
        lineJoin: stroke.getLineJoin() ?? undefined,
        lineDash: stroke.getLineDash() ?? undefined,
        lineDashOffset: stroke.getLineDashOffset() ?? undefined,
        miterLimit: stroke.getMiterLimit() ?? undefined
    };
}

// Function to extract properties from a Text object
function extractText(text) {
    if (!text)
        return undefined;
    return {
        font: text.getFont() ?? undefined,
        maxAngle: text.getMaxAngle() ?? undefined,
        offsetX: text.getOffsetX() ?? undefined,
        offsetY: text.getOffsetY() ?? undefined,
        overflow: text.getOverflow() ?? undefined,
        placement: text.getPlacement() ?? undefined,
        scale: text.getScale() ?? undefined,
        rotateWithView: text.getRotateWithView() ?? undefined,
        rotation: text.getRotation() ?? undefined,
        text: text.getText() ?? undefined,
        textAlign: text.getTextAlign() ?? undefined,
        textBaseline: text.getTextBaseline() ?? undefined,
        fill: extractFill(text.getFill() ?? undefined),
        stroke: extractStroke(text.getStroke() ?? undefined),
        backgroundFill: extractFill(text.getBackgroundFill() ?? undefined),
        backgroundStroke: extractStroke(text.getBackgroundStroke() ?? undefined),
        padding: text.getPadding() ?? undefined
    };
}

// Function to extract properties from an Icon object
function extractIcon(icon) {
    if (!icon)
        return undefined;
    return {
        anchor: icon.getAnchor() ?? undefined,
        anchorOrigin: icon.getAnchorOrigin() ?? undefined,
        anchorXUnits: icon.getAnchorXUnits() ?? undefined,
        anchorYUnits: icon.getAnchorYUnits() ?? undefined,
        color: icon.getColor() ?? undefined,
        crossOrigin: icon.getCrossOrigin() ?? undefined,
        img: icon.getImg() ?? undefined,
        imgSize: icon.getImgSize() ?? undefined,
        offset: icon.getOffset() ?? undefined,
        offsetOrigin: icon.getOffsetOrigin() ?? undefined,
        opacity: icon.getOpacity() ?? undefined,
        scale: icon.getScale() ?? undefined,
        snapToPixel: icon.getSnapToPixel() ?? undefined,
        rotateWithView: icon.getRotateWithView() ?? undefined,
        rotation: icon.getRotation() ?? undefined,
        size: icon.getSize() ?? undefined,
        src: icon.getSrc() ?? undefined,
        displacement: icon.getDisplacement() ?? undefined
    };
}

// Function to extract properties from a Circle object
function extractCircle(circle) {
    if (!circle)
        return undefined;
    return {
        radius: circle.getRadius() ?? undefined,
        fill: extractFill(circle.getFill() ?? undefined),
        stroke: extractStroke(circle.getStroke() ?? undefined),
        displacement: circle.getDisplacement() ?? undefined,
        scale: circle.getScale() ?? undefined,
        rotation: circle.getRotation() ?? undefined,
        rotateWithView: circle.getRotateWithView() ?? undefined
    };
}

// Function to extract properties from a RegularShape object
function extractShape(shape) {
    if (!shape)
        return undefined;
    return {
        points: shape.getPoints() ?? undefined,
        radius: shape.getRadius() ?? undefined,
        radius1: shape.getRadius1() ?? undefined,
        radius2: shape.getRadius2() ?? undefined,
        angle: shape.getAngle() ?? undefined,
        fill: extractFill(shape.getFill() ?? undefined),
        stroke: extractStroke(shape.getStroke() ?? undefined),
        displacement: shape.getDisplacement() ?? undefined,
        scale: shape.getScale() ?? undefined,
        rotation: shape.getRotation() ?? undefined,
        rotateWithView: shape.getRotateWithView() ?? undefined
    };
}

// Function to create a JavaScript object from an OpenLayers Style object
export function extractStyles(style) {
    const processStyle = (style) => {
        return {
            fill: extractFill(style.getFill() ?? undefined),
            stroke: extractStroke(style.getStroke() ?? undefined),
            text: extractText(style.getText() ?? undefined),
            image: (() => {
                const image = style.getImage();
                if (image instanceof Icon)
                    return extractIcon(image);
                if (image instanceof Circle)
                    return extractCircle(image);
                if (image instanceof RegularShape)
                    return extractShape(image);
                return undefined;
            })(),
            zIndex: style.getZIndex() ?? undefined,
            geometry: typeof style.getGeometry() === 'function' ? style.getGeometry().name : style.getGeometry() ?? undefined,
            renderer: style.getRenderer() ?? undefined
        };
    };

    if (Array.isArray(style)) {
        return style.map(processStyle);
    } else {
        return processStyle(style);
    }
}

export const defaultStyle = [{
        fill: {
            color: 'rgba(255,255,255,0.2)'
        },
        stroke: {
            color: 'rgb(30,50,50)',
            width: 3
        }
    }, {
        image: {
            circle: {
                radius: 7,
                fill: {
                    color: 'gray'
                },
                stroke: {
                    color: 'white',
                    width: 2,
                }
            }
        }
    }
];

export const modifyStyles = createStyles({
    image: {
        circle: {
            radius: 5,
            fill: {
                color: 'red'
            },
            stroke: {
                color: 'white',
                width: 2
            }
        }
    }
});

export function customStyles(feature, caller = null) {
    function tip(point, text) {
        const coord = point.getCoordinates(),
        color = 'rgba(0,0,0,1)',
        svg = `data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' fill='${encodeURIComponent(color)}' width='43' height='43' %3E%3Cpath d='m 0,43 v -32 l 11,13 23,-23 8,8 -23,23 13,11 z' /%3E%3C/svg%3E%0A`;
        styles.push({
            geometry: {
                type: 'Point',
                coordinates: [coord[0], coord[1]]
            },
            image: {
                icon: {
                    src: svg,
                    scale: 0.5,
                    anchor: [0, 1],
                }
            },
            text: {
                text: text,
                font: 'bold 14px titr',
                textAlign: 'left',
                offsetX: 16,
                offsetY: -23,
                stroke: {
                    color: '#ffffff',
                    width: 2
                },
                fill: {
                    color: '#000000'
                },
                backgroundFill: {
                    color: color
                },
                padding: [2, 5, 2, 5]
            },
            zIndex: 2000,
        });
    }
    function label(text) {
        return {
            text: text,
            font: 'bold 15px titr',
            placement: 'line',
            maxAngle: 0,
            stroke: {
                color: '#ffffff',
                width: 2
            },
            fill: {
                color: '#000000'
            },
        }
    }
    function lineLabels(line, stroke) {
        const segments = line.forEachSegment((start, end) => {
            const s = new LineString([start, end]);
            styles.push({
                geometry: {
                    type: 'LineString',
                    coordinates: [start, end]
                },
                text: label(formatLength(s)),
                stroke: stroke ?? undefined,
                zIndex: 1900
            })
        });
    }
    let styles = feature.get('_styles')??JSON.parse(JSON.stringify(defaultStyle));
	let values=feature.get('_values');
    if (values && values.showInfo.length > 0 === false)
        return createStyles(styles);

    if (styles[0].text)
        styles[0].text.offsetY = 20;
    const geometry = feature.getGeometry(),
    geom = geometry.get('Name')||feature.get('_type') || geometry.getType();
    if (geom == 'Elipse' || geom=='Circle') {
        let coordinates = geometry.getCoordinates()[0],
        center = geometry.getInteriorPoint().getCoordinates();
        lineLabels(new LineString([center, coordinates[Math.floor(1 * coordinates.length / 4)]]), {
            color: '#778899',
            width: 2,
            lineDash: [7, 7]
        });
        lineLabels(new LineString([center, coordinates[Math.floor(2 * coordinates.length / 4)]]), {
            color: '#778899',
            width: 2,
            lineDash: [7, 7]
        });
    } else if (geom == 'LineString') {
        if (styles[0].stroke && styles[0].fill) {
            const w = parseInt(styles[0].stroke.width);
            const bgStroke = {
                stroke: {
                    color: styles[0].fill.color,
                    width: w + (Math.ceil(w / 4) * 3)
                }
            };
            delete (styles[0].fill);
            styles.unshift(bgStroke);
			
        }
        lineLabels(geometry);
        let coordinates = geometry.getCoordinates();
		if (coordinates.length>2 && (caller=='ruler' || feature.get('_type')=='LineString')){
			tip(new Point(coordinates[0]),formatLength(geometry));
		}
		
    } else if (geom == 'Polygon' || geom == 'Rectangle') {
        const coordinates = geometry.getCoordinates();
        coordinates.forEach(coord => {
            coord.forEach((c, n, a) => {
                let x = (n < a.length - 1) ? n + 1 : 0;
                if (geom == 'Rectangle' && n < 2)
                    return;
                lineLabels(new LineString([c, a[x]]));
            })
        });
        const center = geometry.getInteriorPoint(),
        text = formatArea(geometry);
        if (text)
            tip(center, text)
    } else if (geom == 'Point' || geom == 'Marker') {
        tip(geometry, toStringXY(toLonLat(geometry.getCoordinates()).reverse(), 5));
    }
	if (caller==='ruler' && geom!=='Point'){
			 styles[1]={
				stroke: {
				color:'rgb(255, 215, 0)',
				width:2,
				lineDash:[5,5]
				}
			};
			styles[0]={
				stroke: {
					color: 'rgb(30,50,50)',
					width: 5
				}
			};
	}
    return createStyles(styles);
}
