import XYZ from 'ol/source/XYZ.js';
import TileLayer from 'ol/layer/Tile.js';

export class Satellite extends TileLayer{
    constructor() {
		super({
			source: new XYZ({
				url: 'https://tiles.behrah.com/bng/{z}/{x}/{y}.jpg',
				crossOrigin: '',
				maxZoom:15
			}),
			zIndex:10,
		});
	}
}