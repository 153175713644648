import Dialog from './dialog.js';

const content = `
	<h5>زمینه نقشه</h5>
	<div class="mb-3">
		<label class="">
			<input class="form-check-input" type="radio" name="background" value="none">
			سفید
		</label>
		<label class="mx-3">
			<input class="form-check-input" type="radio" name="background" value="satllite">
			ماهواره
		</label>
		<label class="">
			<input class="form-check-input" type="radio" name="background" value="terrain">
			ارتفاعات
		</label>
	</div>
		<label class="d-block mb-3">
			<input class="form-check-input" type="checkbox" name="graticule" value="on">
			درجه بندی نقشه - گرتیکول
		</label>
	<h5>توپوگرافی</h5>
	<div class="">
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="admins">
				حدود مرزی
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="roads">
				جاده‌ها و خیابان‌ها
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="lands">
				کاربری زمینها
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="waters">
				دریا، دریاچه‌ها، رودخانه‌ها و آبراه ها
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="mountains">
				قله ها و ارتفاعات
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="buildings">
				ساختمانها
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="poi">
				اماکن عمومی
			</label>
			<label class="d-block mb-3">
				<input class="form-check-input map-layers" type="checkbox" name="geomap" value="labels">
				نامها و نوشته‌ها
			</label>
		<!--div class="mb-3">
			<label class="form-label fw-bold d-block">رنگ آمیزی نقشه</label>
			<input class="form-range mb-3" type="range" name="saturation" min="0" max="200" value="0">
		</div-->
		<label class="d-block mb-3">
			<input class="form-check-input" type="checkbox" name="topography" value="contour">
			منحنی‌‌های میزان - کانتور(۱:۲۰۰,۰۰۰)
		</label>
		<div class="layers px-4">
			<label class="d-block mb-3">
				<input class="form-check-input" type="checkbox" name="topography" value="only100">
				هر ۱۰۰ متر
			</label>
		</div>
	</div>
	</div>
`;

export default function settings() {
    let data = JSON.parse(localStorage.getItem('settings'));
    new Dialog('default-dialog', 'تنظیمات نقشه', content, [{
                "text": 'تایید',
                "class": "btn btn-primary",
                "id": "savedata"
            }, {
                "text": 'صرفنظر',
                "class": "btn btn-default",
                "data-bs-dismiss": "modal"
            }
        ], data).onclick = function (btn) {
        if (btn.id == 'savedata') {
            const settings = this.getValues();
            setSettings(settings)
        }
        this.hide();
    }
}

export function setSettings(settings) {
    localStorage.setItem('settings', JSON.stringify(settings));
    document.dispatchEvent(new CustomEvent("settingsChanged",{detail:settings}));
}

export function getSettings() {
	var settings;
    try {
        settings = JSON.parse(localStorage.getItem('settings'));
        if (!settings)
            throw 'error';
    } catch (e) {
        settings = {
            "background": "none",
            "topography": [],
            "geomap": ["admins", "roads", "lands", "waters", "buildings", "labels"]
        };
        localStorage.setItem('settings', JSON.stringify(settings));
    }
    return settings;
}

getSettings();
