import {
    getArea,
    getLength,
    getDistance
}
from 'ol/sphere.js';
import proj4 from 'proj4';
import {
    transform
}
from 'ol/proj';
import {
    register
}
from 'ol/proj/proj4';
import {
    toStringHDMS,
    toStringXY
}
from 'ol/coordinate.js';
import {
    decrypt,
    encrypt
}
from './crypto.js';
import osmtogeojson from 'osmtogeojson';
import {
    Point,
    LineString,
    Polygon,
    MultiPoint,
    MultiLineString,
    MultiPolygon
}
from 'ol/geom';
import Feature from 'ol/feature';
const cryptHashString='Hello topomap';
export function isMobileDevice() {
    // Regular expression to check if the user agent contains any of the common mobile keywords
    const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    // Get the user agent string
    const userAgent = navigator.userAgent;

    // Check if the user agent matches the mobile keywords
    return mobileKeywords.test(userAgent);
}

export function svgToUrl(svgString, width, height) {
    const base64SVG = btoa(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="${width}px" height="${height}px">${svgString}</svg>`);
    return `url('data:image/svg+xml;base64,${base64SVG}')`;
}

export const formatLen = function (length) {
    if (length == 0)
        return '';
    let output;
    if (length > 1000) {
        output = Math.round((length / 1000) * 100) / 100 + ' ' + 'کیلومتر';
    } else {
        output = Math.round(length * 10) / 10 + ' ' + 'متر';
    }
    return output;
};

export const formatLength = function (line) {
    return formatLen(line.getLength());
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
export const formatArea = function (polygon) {
    const area = getArea(polygon);
    if (Math.round(area) == 0)
        return '';
    let output;
    if (area > 10000000) {
        output = (area / 1000000).toFixed(1) + ' کیلومتر مربع';
    } else if (area > 10000) {
        output = (area / 10000).toFixed(1) + ' هکتار';
    } else {
        output = area.toFixed(0) + ' متر مربع';
    }
    return output;
};

function fallbackCopyTextToClipboard(text, callback) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        callback(successful ? 'clipboard' : 'error');
    } catch (err) {
        callback(err)
    }
    document.body.removeChild(textArea);
}

export function toClipboard(text, callback) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        callback('clipboard');
    }, function (err) {
        callback(err);
    });
}

export function shareLink(url, callback) {
    const data = {
        title: "مسیریاب بهراه",
        text: "برای دیدن مکان و مسیریابی بروی لینک زیر کلیک کنید.\n",
        url: url
    };
    if (typeof(navigator.share) == 'function' && navigator.canShare(data)) {
        try {
            navigator.share(data)
            .then(() => {
                callback('share');
            })
            .catch(error => {
                callback(error.message);
            })
        } catch (error) {
            callback(error.message);
        }
    } else {
        toClipboard(url, callback);
    }
}

// Register UTM projections dynamically for OpenLayers
function registerUTMProjections() {
    for (let zone = 1; zone <= 60; zone++) {
        const northProjCode = `EPSG:326${zone.toString().padStart(2, '0')}`;
        const southProjCode = `EPSG:327${zone.toString().padStart(2, '0')}`;
        proj4.defs(northProjCode, `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs`);
        proj4.defs(southProjCode, `+proj=utm +zone=${zone} +datum=WGS84 +south +units=m +no_defs`);
        register(proj4);
    }
}

registerUTMProjections();
export function lonlatToUTM(lonlat) {
    // Determine UTM zone from longitude
    const zoneNumber = Math.floor((lonlat[0] + 180) / 6) + 1;
    const isNorthernHemisphere = lonlat[1] >= 0;

    // Define UTM projection for the specific zone and hemisphere
    let utmProjCode = `EPSG:326${zoneNumber < 10 ? '0' + zoneNumber : zoneNumber}`;
    if (!isNorthernHemisphere) {
        utmProjCode = `EPSG:327${zoneNumber < 10 ? '0' + zoneNumber : zoneNumber}`;
    }

    // Transform the coordinates using OpenLayers
    const utmCoords = transform(lonlat, 'EPSG:4326', utmProjCode);

    const result = {
        zoneNumber: zoneNumber,
        hemisphere: isNorthernHemisphere ? 'N' : 'S',
        easting: utmCoords[0],
        northing: utmCoords[1],
    };

    result.text = `${result.zoneNumber} ${result.hemisphere} ${utmCoords[0].toFixed(3)}, ${utmCoords[1].toFixed(3)}`;

    return result;
}

export function hdmsToLatLng(hdms) {
    function parseDMS(dms, direction) {
        let parts = dms.split(/[^\d+(\.\d+)?]+/).filter(Boolean).map(Number);
        let degrees = parts[0] || 0;
        let minutes = parts[1] || 0;
        let seconds = parts[2] || 0;
        let decimal = degrees + minutes / 60 + seconds / 3600;
        if (/[SW]/i.test(direction))
            decimal = -decimal;
        return decimal;
    }

    let regex = /(\d+[^a-zA-Z]*\d*[′′″″]*)\s*([NS])[^a-zA-Z]*?(\d+[^a-zA-Z]*\d*[′′″″]*)\s*([EW])/i;
    let match = hdms.match(regex);

    if (!match)
        return false;

    let lat = parseDMS(match[1], match[2]);
    let lng = parseDMS(match[3], match[4]);

    return [lng, lat];
}

function normalizeLatitude(lat) {
    while (lat > 90 || lat < -90) {
        if (lat > 90) {
            lat = 180 - lat;
        } else if (lat < -90) {
            lat = -180 - lat;
        }
    }
    return lat;
}

function normalizeLongitude(lng) {
    while (lng > 180) {
        lng -= 360;
    }
    while (lng < -180) {
        lng += 360;
    }
    return lng;
}

export function degToLatLng(degString) {
    // Remove all extra spaces and split by comma
    const cleanedString = degString.replace(/\s+/g, '').split(',');

    // Check if the cleaned string array has two elements
    if (cleanedString.length !== 2)
        return false;

    // Convert the two parts to numbers
    let lat = parseFloat(cleanedString[0]);
    let lng = parseFloat(cleanedString[1]);

    // Validate the parsed values
    if (isNaN(lat) || isNaN(lng))
        return false;

    // Return the result as an object
    return [normalizeLongitude(lng), normalizeLatitude(lat)];
}

export function utmToLatLng(utmString) {
    // Define the regular expression to match different UTM formats
    //  const regex = /(\d{1,2})?\s*([NS])?\s*([\d.]+)\s*,\s*([\d.]+)/i;
    const regex = /(\d{1,2})\s*([NS])\s*([\d.]+)\s*,\s*([\d.]+)/i;
    const match = utmString.match(regex);

    if (!match)
        return false;
    // Extract the components from the match
    const zone = match[1] ? parseInt(match[1]) : null;
    const hemisphere = match[2] ? match[2].toUpperCase() : null;
    const easting = parseFloat(match[3]);
    const northing = parseFloat(match[4]);

    if (!zone)
        return false;

    // Define the UTM projection string
    const utmProjString = `+proj=utm +zone=${zone} +${hemisphere === 'N' ? 'north' : 'south'} +datum=WGS84`;

    // Define the WGS84 projection string
    const wgs84ProjString = `+proj=longlat +datum=WGS84 +no_defs`;

    // Perform the conversion using proj4
    return proj4(utmProjString, wgs84ProjString, [easting, northing]);

}

export async function getOverpassFeature(idType, id, callback) {
    const overpassUrl = "https://overpass-api.de/api/interpreter";
    const overpassQuery = `
        [out:json];
        ${idType}(${id});
        out geom;
        >;
        out geom;
    `;

    if (typeof(callback) !== 'function') {
        return false;
    }

    try {
        const response = await fetch(overpassUrl, {
            method: 'POST',
            body: overpassQuery,
            headers: {
                'Content-Type': 'text/plain'
            }
        });

        if (!response.ok) {
            callback(null);
            return;
        }

        const overpassData = await response.json();
        const geojson = osmtogeojson(overpassData);
		if (idType==='rel') 
			    geojson.features = geojson.features.filter(f => f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon');
        // Call the callback with the resulting GeoJSON data
        callback(geojson);

    } catch (error) {
        console.error("Error:", error);
        callback(false);
    }
}

export function getDateTimeString() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}-${hours}-${minutes}`;
}

// Function to save file
export async function saveFile(filename, content, header = null) {
 //   let data = await encrypt(content, cryptHashString);
	const obj={
		header:header,
		content:content
	},
	data=await encrypt(btoa(JSON.stringify(obj)),cryptHashString);
		
    const blob = new Blob([data], { type: 'text/plain' });
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Append the link to the body (not necessary for it to work, but necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
}

// Function to load file
export function loadFile() {
    return new Promise((resolve, reject) => {
        // Create and append the file input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.topomap';
        input.id = 'fileInput';
        input.style.display = 'none';

        input.addEventListener('change', async (event) => {
            const file = event.target.files[0];

            if (!file) {
                reject(new Error('No file selected'));
                return;
            }

            // Check file extension
            if (!file.name.endsWith('.topomap')) {
                reject(new Error('Invalid file extension'));
                return;
            }

            const reader = new FileReader();

            reader.onload = async function (e) {
                try {
                    const fileContent = e.target.result;
                    let content= atob(await decrypt(fileContent, cryptHashString)); // Decode base64 after decryption
					
                    // Try to parse as JSON
                    try {
                        const json = JSON.parse(content);
                        if (json.header !== 'Topomap Drawings') {
                            throw new Error('Invalid JSON format or missing special header');
                        }
                        resolve(json.content);
                    } catch (error) {
						reject(error);
                    }
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsText(file);
        });

        document.body.appendChild(input);
        input.click();
    });
}


export function OLFeature(vectorFeature) {
    const {
        type_,
        flatCoordinates_,
        properties_,
        ends_,
        stride_
    } = vectorFeature;
    let geometry;

    switch (type_) {
        case 'Point':
            geometry = new Point(flatCoordinates_.slice(0, stride_));
            break;

        case 'LineString':
            const lineStringCoords = [];
            for (let i = 0; i < flatCoordinates_.length; i += stride_) {
                lineStringCoords.push(flatCoordinates_.slice(i, i + stride_));
            }
            geometry = new LineString(lineStringCoords);
            break;

        case 'Polygon':
            const polygonCoords = [];
            let ringOffset = 0;
            for (let end of ends_) {
                const ringCoords = [];
                for (let i = ringOffset; i < end; i += stride_) {
                    ringCoords.push(flatCoordinates_.slice(i, i + stride_));
                }
                polygonCoords.push(ringCoords);
                ringOffset = end;
            }
            geometry = new Polygon(polygonCoords);
            break;

        case 'MultiPoint':
            const multiPointCoords = [];
            for (let i = 0; i < flatCoordinates_.length; i += stride_) {
                multiPointCoords.push(flatCoordinates_.slice(i, i + stride_));
            }
            geometry = new MultiPoint(multiPointCoords);
            break;

        case 'MultiLineString':
            const multiLineStringCoords = [];
            let lineOffset = 0;
            for (let end of ends_) {
                const lineCoords = [];
                for (let i = lineOffset; i < end; i += stride_) {
                    lineCoords.push(flatCoordinates_.slice(i, i + stride_));
                }
                multiLineStringCoords.push(lineCoords);
                lineOffset = end;
            }
            geometry = new MultiLineString(multiLineStringCoords);
            break;

        case 'MultiPolygon':
            const multiPolygonCoords = [];
            let polygonOffset = 0;
            for (let polygonEnds of ends_) {
                const polygonCoords = [];
                let ringOffset = polygonOffset;
                for (let ringEnd of polygonEnds) {
                    const ringCoords = [];
                    for (let i = ringOffset; i < ringEnd; i += stride_) {
                        ringCoords.push(flatCoordinates_.slice(i, i + stride_));
                    }
                    polygonCoords.push(ringCoords);
                    ringOffset = ringEnd;
                }
                multiPolygonCoords.push(polygonCoords);
                polygonOffset = polygonEnds[polygonEnds.length - 1];
            }
            geometry = new MultiPolygon(multiPolygonCoords);
            break;

        default:
            console.error('Unsupported geometry type:', type_);
            return null;
    }
    
    const feature = new Feature({
        geometry: geometry,
        ...properties_
    });

    return feature;
}
export function replaceExtension(filename, extension) {
    // Check if filename contains a dot
    if (filename.indexOf('.') === -1) {
        // If no dot is found, append the extension
        return filename + '.' + extension;
    }

    // Split the filename into two parts: name and old extension
    let parts = filename.split('.');
    // Remove the last part which is the old extension
    parts.pop();
    // Join the remaining parts back to the base filename
    let baseName = parts.join('.');
    
    // Return the base filename with the new extension
    return baseName + '.' + extension;
}

export function newFilename(extension) {
    // Get the current date
    const now = new Date();
    
    // Extract year, month, day, hours, minutes, and seconds
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    // Construct the date string in 'yyyymmdd-hhmmss' format
    const dateString = `${year}${month}${day}-${hours}${minutes}${seconds}`;
    
    // Construct the final filename
    const filename = `topomap-${dateString}.${extension}`;
    
    return filename;
}

export function saveToFile(data, filename, type='text/plain') {
    let blob;

    if (typeof data === 'string') {
        // If data is a string, create a Blob object from it
        blob = new Blob([data], { type: type});
    } else if (data instanceof Blob) {
        // If data is already a Blob object, use it directly
        blob = data;
    } else {
        throw new Error("Data must be a string or a Blob");
    }

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an <a> element and set the download attribute with the filename
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Append the <a> element to the document body (necessary for some browsers)
    document.body.appendChild(a);

    // Programmatically click the <a> element to trigger the download
    a.click();

    // Remove the <a> element from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url);
}


// Function to check if a coordinate is within the extent
function isCoordinateInExtent(coordinate, extent) {
    var [x, y] = coordinate;
    var [minX, minY, maxX, maxY] = extent;
    return x >= minX && x <= maxX && y >= minY && y <= maxY;
}

// Function to filter feature coordinates based on the extent
export function filterFeatureCoordinates(features, extent) {
    var filteredFeatures = [];

    features.forEach(function(feature) {
        var geometry = feature.getGeometry().clone();
        var type = geometry.getType();
        var newCoordinates;

        switch (type) {
            case 'Point':
                if (isCoordinateInExtent(geometry.getCoordinates(), extent)) {
                    newCoordinates = geometry.getCoordinates();
                } else {
                    newCoordinates = null;
                }
                break;

            case 'MultiPoint':
                newCoordinates = geometry.getCoordinates().filter(function(coordinate) {
                    return isCoordinateInExtent(coordinate, extent);
                });
                if (newCoordinates.length === 0) newCoordinates = null;
                break;

            case 'LineString':
                newCoordinates = geometry.getCoordinates().filter(function(coordinate) {
                    return isCoordinateInExtent(coordinate, extent);
                });
                if (newCoordinates.length === 0) newCoordinates = null;
                break;

            case 'MultiLineString':
                newCoordinates = geometry.getCoordinates().map(function(lineString) {
                    return lineString.filter(function(coordinate) {
                        return isCoordinateInExtent(coordinate, extent);
                    });
                }).filter(function(lineString) {
                    return lineString.length > 0;
                });
                if (newCoordinates.length === 0) newCoordinates = null;
                break;

            case 'Polygon':
                newCoordinates = geometry.getCoordinates().map(function(ring) {
                    return ring.filter(function(coordinate) {
                        return isCoordinateInExtent(coordinate, extent);
                    });
                }).filter(function(ring) {
                    return ring.length > 0;
                });
                if (newCoordinates.length === 0) newCoordinates = null;
                break;

            case 'MultiPolygon':
                newCoordinates = geometry.getCoordinates().map(function(polygon) {
                    return polygon.map(function(ring) {
                        return ring.filter(function(coordinate) {
                            return isCoordinateInExtent(coordinate, extent);
                        });
                    }).filter(function(ring) {
                        return ring.length > 0;
                    });
                }).filter(function(polygon) {
                    return polygon.length > 0;
                });
                if (newCoordinates.length === 0) newCoordinates = null;
                break;

            default:
                console.error('Geometry type not supported:', type);
                return;
        }

        if (newCoordinates !== null) {
            geometry.setCoordinates(newCoordinates);
            var newFeature = new Feature({
                geometry: geometry,
                properties: feature.getProperties()
            });
            filteredFeatures.push(newFeature);
        }
    });

    return filteredFeatures;
}
